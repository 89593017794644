<script setup lang="ts">
import { useStorage } from "@vueuse/core";
import { onMounted, ref } from "vue";
const isDarkMode = useStorage("isDark", false);
const width = ref(window.innerWidth);

const handleResize = () => {
  width.value = window.innerWidth;
}

onMounted(() => {
  window.addEventListener("resize", handleResize);
})

</script>

<template>
  <svg v-if="isDarkMode" :width="(width > 1024) ? (width * 0.103125) : 198" height="54" viewBox="0 0 198 54" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="54" height="54" rx="10.5" fill="#FFCD1A" />
    <mask id="mask0_658_107804" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="54" height="54">
      <rect width="54" height="54" rx="10.5" fill="#FFCD1A" />
    </mask>
    <g mask="url(#mask0_658_107804)">
      <path
        d="M12.1434 54.9264C10.5686 54.9264 9.0556 54.6498 7.64932 54.1425C5.9734 53.5417 4.45103 52.611 3.15523 51.4285C2.78259 51.089 2.42868 50.7286 2.09537 50.349C0.934396 49.0292 0.0205965 47.4787 -0.569253 45.7717C-1.06735 44.3394 -1.33887 42.7984 -1.33887 41.1944V13.7305H43.604V15.6072C43.5272 16.5303 43.3811 17.4305 43.1695 18.3078C42.7838 19.9308 42.1808 21.468 41.3925 22.8851H23.3993V22.908C17.2442 23.2837 12.34 28.3932 12.149 34.7099H12.1453V54.9264H12.1434Z"
        fill="#FF661A" />
      <path d="M7.64884 9.15464H-1.33936V0L7.64884 9.15464Z" fill="#FF661A" />
      <path d="M25.6274 0V9.15464H16.6392L25.6274 0Z" fill="#FF661A" />
      <path d="M12.1429 9.15625H-1.33936V13.7336H12.1429V9.15625Z" fill="#333333" />
      <path d="M25.6276 9.15625H12.1453V13.7336H25.6276V9.15625Z" fill="#212121" />
      <path
        d="M43.6032 13.7305V15.6072C43.5264 16.5303 43.3804 17.4305 43.1688 18.3078C42.783 19.9308 42.1801 21.468 41.3917 22.8851H35.761V13.7305H43.6032Z"
        fill="#3F2214" />
      <path
        d="M16.2162 19.6031L16.19 19.6298C15.8137 20.0131 15.2294 20.057 14.7894 19.7557C14.0347 19.235 13.1247 18.9317 12.1435 18.9317C11.1623 18.9317 10.2522 19.235 9.49758 19.7557C9.05753 20.0589 8.47142 20.0131 8.09691 19.6298L8.0707 19.6031C7.58758 19.111 7.65687 18.2909 8.2205 17.898C9.33654 17.1199 10.6885 16.6641 12.1435 16.6641C13.5984 16.6641 14.9485 17.1199 16.0664 17.898C16.6301 18.2909 16.6994 19.111 16.2162 19.6031Z"
        fill="#3F2214" />
      <path
        d="M41.3899 22.8867C40.7495 24.0501 39.9817 25.1315 39.1091 26.1156C38.6897 26.5886 38.2459 27.0406 37.7796 27.464C36.8152 28.349 35.7535 29.1233 34.615 29.7737C33.2237 30.5709 31.7144 31.1831 30.1209 31.5741C29.1303 31.8182 28.1061 31.9765 27.0556 32.0414C26.6885 32.0662 26.3197 32.0776 25.947 32.0776C25.8403 32.0776 25.7336 32.0757 25.6268 32.0738V41.196C25.6268 42.8 25.3553 44.341 24.8572 45.7733C24.2674 47.4803 23.3536 49.0308 22.1926 50.3506C21.8593 50.7302 21.5054 51.0906 21.1327 51.4301C19.8369 52.6126 18.3146 53.5433 16.6386 54.146C15.2342 54.6533 13.7212 54.928 12.1445 54.928V35.0929C12.1445 28.3509 17.5094 22.8867 24.1269 22.8867H41.3899Z"
        fill="#FFE9CF" />
    </g>
    <path
      d="M78.2879 37.1057C75.3579 37.1057 72.6875 35.4368 72.6875 30.8749V22.1219H71.2503C70.6102 22.1219 70.0913 21.603 70.0913 20.9629V19.3496C70.0913 18.7095 70.6102 18.1905 71.2503 18.1905H72.6875V15.0844C72.6875 14.4443 73.2064 13.9253 73.8465 13.9253H75.5341C76.1742 13.9253 76.6931 14.4443 76.6931 15.0844V18.1905H78.1303C78.7704 18.1905 79.2893 18.7095 79.2893 19.3496V20.9629C79.2893 21.603 78.7704 22.1219 78.1303 22.1219H76.6931V30.0218C76.6931 32.4697 77.509 33.0631 78.325 33.1002C78.4642 33.1084 78.5944 33.1147 78.7188 33.1193C79.3888 33.144 79.9569 33.6686 79.9569 34.339V35.9467C79.9569 36.5868 79.438 37.1057 78.7979 37.1057H78.2879Z"
      fill="#FFE9CF" />
    <path
      d="M97.0614 31.5424C97.3815 31.5424 97.644 31.8031 97.6147 32.1218C97.3224 35.2929 94.6245 37.1512 91.2988 37.2912C90.3715 37.3283 89.4072 37.3283 88.48 37.2912C84.8083 37.1428 82.175 34.4725 82.1379 31.3941C82.1008 28.427 82.1008 26.5726 82.1379 23.6055C82.175 20.0821 84.8824 18.0793 88.4429 17.8197C88.9993 17.7826 90.7795 17.7826 91.2988 17.8197C94.8964 18.0793 97.5667 20.1562 97.6409 23.6055C97.678 24.6811 97.678 28.7237 97.678 29.2059H86.1434V31.0232C86.1434 32.4697 87.3674 33.2114 88.48 33.2856C89.1476 33.3227 90.5941 33.3227 91.2617 33.2856C92.2677 33.2246 93.1736 32.888 93.4642 32.1109C93.5763 31.8111 93.8206 31.5424 94.1407 31.5424H97.0614ZM86.1434 24.9917C86.1434 25.3117 86.4029 25.5712 86.723 25.5712H93.0558C93.3759 25.5712 93.6353 25.3117 93.6353 24.9917V24.236C93.6353 22.7895 92.3743 21.8994 91.2617 21.8252C90.7424 21.7881 89.0364 21.7881 88.48 21.8252C87.3674 21.8994 86.1434 22.7895 86.1434 24.236V24.9917Z"
      fill="#FFE9CF" />
    <path
      d="M115.715 31.1716C116.038 31.1716 116.301 31.4358 116.269 31.7567C115.957 34.8872 113.423 37.0094 110.033 37.2541C109.365 37.2912 107.362 37.2912 106.695 37.2541C103.06 36.9945 100.39 34.1387 100.353 31.0603C100.316 28.1674 100.316 26.8693 100.353 23.9764C100.39 20.898 103.06 18.0793 106.695 17.8197C108.067 17.7455 108.624 17.7084 110.07 17.8197C113.428 18.0295 115.994 20.1522 116.275 23.2859C116.304 23.6047 116.041 23.8651 115.721 23.8651H112.801C112.481 23.8651 112.229 23.5966 112.107 23.3007C111.767 22.4744 110.726 21.8863 109.81 21.8252C108.549 21.751 108.364 21.751 106.917 21.8252C105.768 21.8994 104.395 22.7895 104.358 24.6069C104.321 26.5355 104.321 28.5012 104.358 30.3927C104.395 32.5068 105.768 33.1743 106.917 33.2485C107.585 33.2856 109.143 33.2856 109.81 33.2485C110.825 33.1851 111.867 32.769 112.148 31.7451C112.233 31.4365 112.481 31.1716 112.801 31.1716H115.715Z"
      fill="#FFE9CF" />
    <path
      d="M120.279 36.7349C119.639 36.7349 119.12 36.2159 119.12 35.5758V10.8192C119.12 10.1791 119.639 9.66016 120.279 9.66016H121.966C122.606 9.66016 123.125 10.1791 123.125 10.8192V17.7084H127.798C131.062 17.7084 134.029 19.9337 134.066 24.5327C134.099 27.2647 134.103 32.3335 134.077 35.5914C134.072 36.226 133.556 36.7349 132.921 36.7349H131.22C130.58 36.7349 130.061 36.2159 130.061 35.5758V24.9036C130.061 22.4186 128.948 21.751 127.761 21.714C126.723 21.6769 124.46 21.6769 123.125 21.714V35.5758C123.125 36.2159 122.606 36.7349 121.966 36.7349H120.279Z"
      fill="#FFE9CF" />
    <path
      d="M148.252 10.8192C148.252 10.1791 148.77 9.66016 149.411 9.66016H151.061C151.701 9.66016 152.22 10.1791 152.22 10.8192V32.2842C152.22 34.8447 150.144 36.9203 147.584 36.9203H143.17C139.573 36.809 136.94 34.1757 136.902 31.0603C136.865 28.2787 136.865 26.5726 136.902 23.7909C136.94 20.7126 139.499 18.2647 143.133 18.0051H148.252V10.8192ZM140.908 30.3927C140.945 31.8762 142.058 32.8776 143.17 32.9147C144.497 32.9436 145.801 32.95 147.118 32.934C147.748 32.9263 148.252 32.4109 148.252 31.7806V23.0774C148.252 22.4593 147.767 21.9494 147.149 21.9349C145.803 21.9031 144.264 21.916 143.17 21.9736C142.058 22.0107 140.945 22.975 140.908 24.4214C140.871 26.7951 140.871 28.0561 140.908 30.3927Z"
      fill="#FFE9CF" />
    <path
      d="M164.61 21.8252C163.238 21.751 162.941 21.751 161.532 21.8252C160.419 21.8994 159.232 22.6783 159.232 24.0505C159.195 26.8693 159.195 27.8336 159.232 30.7265C159.269 32.4697 160.419 33.1743 161.532 33.2485C162.199 33.2856 163.98 33.2856 164.647 33.2485C165.76 33.1743 166.91 32.4697 166.947 30.7265C166.984 27.7594 166.984 27.0176 166.947 24.0505C166.947 22.6783 165.76 21.8623 164.61 21.8252ZM155.227 31.3941C155.19 28.3899 155.19 26.1275 155.227 23.42C155.264 20.3417 157.86 18.0793 161.458 17.8197C162.793 17.7455 163.312 17.7084 164.722 17.8197C168.319 18.0422 170.915 20.3417 170.952 23.42C170.99 26.3871 170.99 28.427 170.952 31.3941C170.915 34.4725 168.356 36.9945 164.722 37.2541C164.054 37.2912 162.125 37.2912 161.458 37.2541C157.823 36.9945 155.264 34.4725 155.227 31.3941Z"
      fill="#FFE9CF" />
    <path
      d="M173.254 44.338C172.639 44.338 172.141 43.8399 172.141 43.2254V41.1876C172.141 40.6153 172.649 40.1809 173.217 40.1099C173.958 39.9987 174.774 39.3311 174.774 37.2541V19.3496C174.774 18.7095 175.293 18.1905 175.933 18.1905H177.621C178.261 18.1905 178.78 18.7095 178.78 19.3496V38.1071C178.78 42.7432 176.184 44.338 173.254 44.338Z"
      fill="#FFE9CF" />
    <path
      d="M191.63 21.8252C190.258 21.751 189.961 21.751 188.552 21.8252C187.439 21.8994 186.252 22.6783 186.252 24.0505C186.215 26.8693 186.215 27.8336 186.252 30.7265C186.289 32.4697 187.439 33.1743 188.552 33.2485C189.219 33.2856 190.999 33.2856 191.667 33.2485C192.78 33.1743 193.929 32.4697 193.966 30.7265C194.004 27.7594 194.004 27.0176 193.966 24.0505C193.966 22.6783 192.78 21.8623 191.63 21.8252ZM182.246 31.3941C182.209 28.3899 182.209 26.1275 182.246 23.42C182.284 20.3417 184.88 18.0793 188.477 17.8197C189.813 17.7455 190.332 17.7084 191.741 17.8197C195.339 18.0422 197.935 20.3417 197.972 23.42C198.009 26.3871 198.009 28.427 197.972 31.3941C197.935 34.4725 195.376 36.9945 191.741 37.2541C191.074 37.2912 189.145 37.2912 188.477 37.2541C184.843 36.9945 182.284 34.4725 182.246 31.3941Z"
      fill="#FFE9CF" />
    <path d="M173.885 14.4267V14.4267C175.624 13.2079 177.939 13.2066 179.68 14.4235V14.4235" stroke="#FF661A"
      stroke-width="2.89755" stroke-linecap="round" />
  </svg>

  <svg v-else :width="(width > 1024) ? (width * 0.103125) : 198" height="54" viewBox="0 0 198 54" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="54" height="54" rx="10.5" fill="#3255D1" />
    <mask id="mask0_45_3570" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="54" height="54">
      <rect width="54" height="54" rx="10.5" fill="#3255D1" />
    </mask>
    <g mask="url(#mask0_45_3570)">
      <path
        d="M12.1434 54.9283C10.5686 54.9283 9.0556 54.6518 7.64932 54.1444C5.9734 53.5437 4.45103 52.6129 3.15523 51.4305C2.78259 51.091 2.42868 50.7305 2.09537 50.351C0.934396 49.0312 0.0205965 47.4806 -0.569253 45.7737C-1.06735 44.3413 -1.33887 42.8003 -1.33887 41.1963V13.7324H43.604V15.6091C43.5272 16.5322 43.3811 17.4324 43.1695 18.3097C42.7838 19.9328 42.1808 21.47 41.3925 22.8871H23.3993V22.91C17.2442 23.2857 12.34 28.3951 12.149 34.7118H12.1453V54.9283H12.1434Z"
        fill="#FF661A" />
      <path d="M7.64878 9.15464H-1.33942V0L7.64878 9.15464Z" fill="#FF661A" />
      <path d="M25.6274 0V9.15464H16.6392L25.6274 0Z" fill="#FF661A" />
      <path d="M12.1429 9.1543H-1.33942V13.7316H12.1429V9.1543Z" fill="#D1D1D1" />
      <path d="M25.6275 9.1543H12.1452V13.7316H25.6275V9.1543Z" fill="white" />
      <path
        d="M43.6032 13.7324V15.6091C43.5264 16.5322 43.3804 17.4324 43.1688 18.3097C42.783 19.9328 42.1801 21.47 41.3917 22.8871H35.761V13.7324H43.6032Z"
        fill="#3F2214" />
      <path
        d="M16.2162 19.6031L16.19 19.6298C15.8137 20.0131 15.2294 20.057 14.7894 19.7557C14.0347 19.235 13.1247 18.9317 12.1435 18.9317C11.1623 18.9317 10.2522 19.235 9.49758 19.7557C9.05753 20.0589 8.47142 20.0131 8.09691 19.6298L8.0707 19.6031C7.58758 19.111 7.65687 18.2909 8.2205 17.898C9.33654 17.1199 10.6885 16.6641 12.1435 16.6641C13.5984 16.6641 14.9485 17.1199 16.0664 17.898C16.6301 18.2909 16.6994 19.111 16.2162 19.6031Z"
        fill="#3F2214" />
      <path
        d="M41.3899 22.8867C40.7495 24.0501 39.9817 25.1315 39.1091 26.1156C38.6897 26.5886 38.2459 27.0406 37.7796 27.464C36.8152 28.349 35.7535 29.1233 34.615 29.7737C33.2237 30.5709 31.7144 31.1831 30.1209 31.5741C29.1303 31.8182 28.1061 31.9765 27.0556 32.0414C26.6885 32.0662 26.3197 32.0776 25.947 32.0776C25.8403 32.0776 25.7336 32.0757 25.6268 32.0738V41.196C25.6268 42.8 25.3553 44.341 24.8572 45.7733C24.2674 47.4803 23.3536 49.0308 22.1926 50.3506C21.8593 50.7302 21.5054 51.0906 21.1327 51.4301C19.8369 52.6126 18.3146 53.5433 16.6386 54.146C15.2342 54.6533 13.7212 54.928 12.1445 54.928V35.0929C12.1445 28.3509 17.5094 22.8867 24.1269 22.8867H41.3899Z"
        fill="#FFE9CF" />
    </g>
    <path
      d="M78.288 37.1057C75.358 37.1057 72.6876 35.4368 72.6876 30.8749V22.1219H71.2504C70.6103 22.1219 70.0914 21.603 70.0914 20.9629V19.3496C70.0914 18.7095 70.6103 18.1905 71.2505 18.1905H72.6876V15.0844C72.6876 14.4443 73.2065 13.9253 73.8467 13.9253H75.5342C76.1743 13.9253 76.6932 14.4443 76.6932 15.0844V18.1905H78.1304C78.7705 18.1905 79.2894 18.7095 79.2894 19.3496V20.9629C79.2894 21.603 78.7705 22.1219 78.1304 22.1219H76.6932V30.0218C76.6932 32.4697 77.5092 33.0631 78.3251 33.1002C78.4644 33.1084 78.5946 33.1147 78.7189 33.1193C79.3889 33.144 79.957 33.6686 79.957 34.339V35.9467C79.957 36.5868 79.4381 37.1057 78.798 37.1057H78.288Z"
      fill="#3255D1" />
    <path
      d="M97.0615 31.5424C97.3816 31.5424 97.6442 31.8031 97.6148 32.1218C97.3225 35.2929 94.6246 37.1512 91.2989 37.2912C90.3717 37.3283 89.4074 37.3283 88.4802 37.2912C84.8084 37.1428 82.1751 34.4725 82.138 31.3941C82.1009 28.427 82.1009 26.5726 82.138 23.6055C82.1751 20.0821 84.8826 18.0793 88.4431 17.8197C88.9994 17.7826 90.7796 17.7826 91.2989 17.8197C94.8965 18.0793 97.5669 20.1562 97.641 23.6055C97.6781 24.6811 97.6781 28.7237 97.6781 29.2059H86.1436V31.0232C86.1436 32.4697 87.3675 33.2114 88.4802 33.2856C89.1477 33.3227 90.5942 33.3227 91.2618 33.2856C92.2679 33.2246 93.1737 32.888 93.4643 32.1109C93.5764 31.8111 93.8208 31.5424 94.1408 31.5424H97.0615ZM86.1436 24.9917C86.1436 25.3117 86.403 25.5712 86.7231 25.5712H93.056C93.376 25.5712 93.6355 25.3117 93.6355 24.9917V24.236C93.6355 22.7895 92.3745 21.8994 91.2618 21.8252C90.7426 21.7881 89.0365 21.7881 88.4802 21.8252C87.3675 21.8994 86.1436 22.7895 86.1436 24.236V24.9917Z"
      fill="#3255D1" />
    <path
      d="M115.715 31.1716C116.038 31.1716 116.301 31.4358 116.269 31.7567C115.957 34.8872 113.423 37.0094 110.033 37.2541C109.365 37.2912 107.363 37.2912 106.695 37.2541C103.06 36.9945 100.39 34.1387 100.353 31.0603C100.316 28.1674 100.316 26.8693 100.353 23.9764C100.39 20.898 103.06 18.0793 106.695 17.8197C108.067 17.7455 108.624 17.7084 110.07 17.8197C113.428 18.0295 115.994 20.1522 116.275 23.2859C116.304 23.6047 116.042 23.8651 115.721 23.8651H112.801C112.481 23.8651 112.229 23.5966 112.107 23.3007C111.767 22.4744 110.726 21.8863 109.81 21.8252C108.549 21.751 108.364 21.751 106.918 21.8252C105.768 21.8994 104.396 22.7895 104.358 24.6069C104.321 26.5355 104.321 28.5012 104.358 30.3927C104.396 32.5068 105.768 33.1743 106.918 33.2485C107.585 33.2856 109.143 33.2856 109.81 33.2485C110.825 33.1851 111.868 32.769 112.148 31.7451C112.233 31.4365 112.481 31.1716 112.801 31.1716H115.715Z"
      fill="#3255D1" />
    <path
      d="M120.279 36.7349C119.639 36.7349 119.12 36.2159 119.12 35.5758V10.8192C119.12 10.1791 119.639 9.66016 120.279 9.66016H121.966C122.606 9.66016 123.125 10.1791 123.125 10.8192V17.7084H127.798C131.062 17.7084 134.029 19.9337 134.066 24.5327C134.099 27.2647 134.103 32.3335 134.078 35.5914C134.073 36.226 133.556 36.7349 132.921 36.7349H131.22C130.58 36.7349 130.061 36.2159 130.061 35.5758V24.9036C130.061 22.4186 128.948 21.751 127.761 21.714C126.723 21.6769 124.46 21.6769 123.125 21.714V35.5758C123.125 36.2159 122.606 36.7349 121.966 36.7349H120.279Z"
      fill="#3255D1" />
    <path
      d="M148.252 10.8192C148.252 10.1791 148.771 9.66016 149.411 9.66016H151.061C151.701 9.66016 152.22 10.1791 152.22 10.8192V32.2842C152.22 34.8447 150.145 36.9203 147.584 36.9203H143.171C139.573 36.809 136.94 34.1757 136.903 31.0603C136.865 28.2787 136.865 26.5726 136.903 23.7909C136.94 20.7126 139.499 18.2647 143.133 18.0051H148.252V10.8192ZM140.908 30.3927C140.945 31.8762 142.058 32.8776 143.171 32.9147C144.497 32.9436 145.801 32.95 147.118 32.934C147.748 32.9263 148.252 32.4109 148.252 31.7806V23.0774C148.252 22.4593 147.767 21.9494 147.149 21.9349C145.803 21.9031 144.264 21.916 143.171 21.9736C142.058 22.0107 140.945 22.975 140.908 24.4214C140.871 26.7951 140.871 28.0561 140.908 30.3927Z"
      fill="#3255D1" />
    <path
      d="M164.61 21.8252C163.238 21.751 162.941 21.751 161.532 21.8252C160.419 21.8994 159.233 22.6783 159.233 24.0505C159.195 26.8693 159.195 27.8336 159.233 30.7265C159.27 32.4697 160.419 33.1743 161.532 33.2485C162.2 33.2856 163.98 33.2856 164.647 33.2485C165.76 33.1743 166.91 32.4697 166.947 30.7265C166.984 27.7594 166.984 27.0176 166.947 24.0505C166.947 22.6783 165.76 21.8623 164.61 21.8252ZM155.227 31.3941C155.19 28.3899 155.19 26.1275 155.227 23.42C155.264 20.3417 157.86 18.0793 161.458 17.8197C162.793 17.7455 163.312 17.7084 164.722 17.8197C168.319 18.0422 170.915 20.3417 170.953 23.42C170.99 26.3871 170.99 28.427 170.953 31.3941C170.915 34.4725 168.356 36.9945 164.722 37.2541C164.054 37.2912 162.125 37.2912 161.458 37.2541C157.823 36.9945 155.264 34.4725 155.227 31.3941Z"
      fill="#3255D1" />
    <path
      d="M173.254 44.338C172.639 44.338 172.141 43.8399 172.141 43.2254V41.1876C172.141 40.6153 172.649 40.1809 173.217 40.1099C173.958 39.9987 174.774 39.3311 174.774 37.2541V19.3496C174.774 18.7095 175.293 18.1905 175.933 18.1905H177.621C178.261 18.1905 178.78 18.7095 178.78 19.3496V38.1071C178.78 42.7432 176.184 44.338 173.254 44.338Z"
      fill="#3255D1" />
    <path
      d="M191.63 21.8252C190.258 21.751 189.961 21.751 188.552 21.8252C187.439 21.8994 186.252 22.6783 186.252 24.0505C186.215 26.8693 186.215 27.8336 186.252 30.7265C186.289 32.4697 187.439 33.1743 188.552 33.2485C189.219 33.2856 191 33.2856 191.667 33.2485C192.78 33.1743 193.93 32.4697 193.967 30.7265C194.004 27.7594 194.004 27.0176 193.967 24.0505C193.967 22.6783 192.78 21.8623 191.63 21.8252ZM182.247 31.3941C182.21 28.3899 182.21 26.1275 182.247 23.42C182.284 20.3417 184.88 18.0793 188.477 17.8197C189.813 17.7455 190.332 17.7084 191.741 17.8197C195.339 18.0422 197.935 20.3417 197.972 23.42C198.009 26.3871 198.009 28.427 197.972 31.3941C197.935 34.4725 195.376 36.9945 191.741 37.2541C191.074 37.2912 189.145 37.2912 188.477 37.2541C184.843 36.9945 182.284 34.4725 182.247 31.3941Z"
      fill="#3255D1" />
    <path d="M173.885 14.4267V14.4267C175.624 13.2079 177.939 13.2066 179.68 14.4235V14.4235" stroke="#FF661A"
      stroke-width="2.89755" stroke-linecap="round" />
  </svg>
</template>
