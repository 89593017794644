<template>
  <svg
    width="100%"
    viewBox="0 0 375 277"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_690_94481)">
      <mask
        id="mask0_690_94481"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="-4"
        y="-188"
        width="404"
        height="441"
      >
        <path
          d="M47.2707 -187.77H400V252.538H47.2707C19.0797 252.538 -3.8125 229.65 -3.8125 201.454V-136.686C-3.8125 -164.877 19.0749 -187.77 47.2707 -187.77Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_690_94481)">
        <path
          d="M130.792 122.711V27.8815L294.307 -189.191L405.882 -184.106L405.588 3.5627L130.792 122.711Z"
          fill="#A67C52"
        />
        <path
          d="M10.4061 130.2V42.2377L83.3957 9.16016L112.933 35.4175L10.4061 130.2Z"
          fill="#754C24"
        />
        <path
          d="M405.213 -62.6445H304.26V19.8242H405.213V-62.6445Z"
          fill="#754C24"
        />
        <path
          d="M-24.8231 98.1562L23.677 66.7943V-85.3984L-24.1928 -57.0226L-24.8231 98.1562Z"
          fill="#603813"
        />
        <path
          d="M88.2397 21.38L163.258 -32.438L298.872 -31.8692L297.118 -184.109H162.727L88.2397 -132.339V21.38Z"
          fill="#603813"
        />
        <path
          d="M405.45 282.159H-24.8231V120.601L166.812 -12.4062H405.45V282.159Z"
          fill="#FFBB6B"
        />
        <path
          d="M229.612 -16.9102L-34.3829 167.934"
          stroke="#8C6239"
          stroke-width="3.79167"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M335.068 -12.4062L-42.4401 252.299"
          stroke="#8C6239"
          stroke-width="3.79167"
          stroke-miterlimit="10"
        />
        <path
          d="M33.8672 287.845L405.451 28.5898"
          stroke="#8C6239"
          stroke-width="3.79167"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M172.737 286.898L405.45 121.961"
          stroke="#8C6239"
          stroke-width="3.79167"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M295.018 286.424L405.45 210.117"
          stroke="#8C6239"
          stroke-width="3.79167"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19.8854 48.2613H-32.9468L19.8854 9.27344V48.2613Z"
          fill="#42210B"
        />
        <path
          d="M19.8854 6.55032H-32.9468L19.8854 -32.4375V6.55032Z"
          fill="#42210B"
        />
        <path
          d="M25.0988 58.9139L51.6405 39.6712L51.4035 -101.18L22.4304 -81.3635L25.0988 58.9139Z"
          fill="#FFE9CF"
        />
        <path
          d="M76.3006 7.63027L75.0494 8.47866C74.1631 9.08059 73.4426 8.84835 73.4426 7.96205V-16.8544C73.4426 -17.7407 74.1631 -18.9493 75.0494 -19.5512L76.3006 -20.3996C77.1869 -21.0015 77.9073 -20.7693 77.9073 -19.883V4.93344C77.9073 5.81975 77.1869 7.02834 76.3006 7.63027Z"
          fill="#18B76B"
        />
        <path
          d="M85.0169 1.41765L83.1921 2.99593C82.4764 3.61681 81.8129 3.38458 81.7134 2.48406L79.2488 -20.1001C79.1492 -21.0053 79.6516 -22.2376 80.3673 -22.8585L82.1921 -24.4368C82.9077 -25.0577 83.5713 -24.8254 83.6708 -23.9249L86.1354 -1.34079C86.2349 -0.435526 85.7325 0.796768 85.0169 1.41765Z"
          fill="#06BAF3"
        />
        <path
          d="M6.55776 12.3519L5.66197 12.9586C5.02687 13.3899 4.51025 13.224 4.51025 12.5889V-13.1375C4.51025 -13.7726 5.02687 -14.64 5.66197 -15.0713L6.55776 -15.6779C7.19286 -16.1092 7.70947 -15.9434 7.70947 -15.3083V10.4182C7.70947 11.0533 7.19286 11.9206 6.55776 12.3519Z"
          fill="#35D4A5"
        />
        <path
          d="M11.34 9.10587L10.4442 9.71253C9.8091 10.1438 9.29248 9.97795 9.29248 9.34285V-16.3836C9.29248 -17.0187 9.8091 -17.8861 10.4442 -18.3174L11.34 -18.924C11.9751 -19.3553 12.4917 -19.1895 12.4917 -18.5543V7.17211C12.4917 7.80721 11.9751 8.67456 11.34 9.10587Z"
          fill="#E443B7"
        />
        <path
          d="M16.4446 5.64492L15.5488 6.25158C14.9137 6.68288 14.3971 6.517 14.3971 5.8819V-19.8446C14.3971 -20.4797 14.9137 -21.347 15.5488 -21.7783L16.4446 -22.385C17.0797 -22.8163 17.5963 -22.6504 17.5963 -22.0153V3.71117C17.5963 4.34628 17.0797 5.21361 16.4446 5.64492Z"
          fill="#E443B7"
        />
        <path
          d="M0.67125 16.3465L-1.84073 18.0481C-2.91661 18.778 -3.7887 18.4983 -3.7887 17.4224V-6.71152C-3.7887 -7.78741 -2.91661 -9.25195 -1.84073 -9.98185L0.67125 -11.6834C1.74714 -12.4133 2.61922 -12.1336 2.61922 -11.0577V13.0762C2.61922 14.1521 1.74714 15.6167 0.67125 16.3465Z"
          fill="#35D4A5"
        />
        <path
          d="M0.396255 57.852L-0.499526 58.4586C-1.13463 58.8899 -1.65125 58.724 -1.65125 58.0889V32.3625C-1.65125 31.7274 -1.13463 30.86 -0.499526 30.4287L0.396255 29.8221C1.03136 29.3908 1.54797 29.5567 1.54797 30.1918V55.9182C1.54797 56.5533 1.03136 57.4207 0.396255 57.852Z"
          fill="#35D4A5"
        />
        <path
          d="M5.17836 54.6059L4.28258 55.2125C3.64747 55.6438 3.13086 55.4779 3.13086 54.8428V29.1164C3.13086 28.4813 3.64747 27.6139 4.28258 27.1826L5.17836 26.576C5.81346 26.1447 6.33007 26.3106 6.33007 26.9457V52.6721C6.33007 53.3072 5.81346 54.1746 5.17836 54.6059Z"
          fill="#E443B7"
        />
        <path
          d="M10.2831 51.1449L9.38732 51.7516C8.75221 52.1829 8.2356 52.017 8.2356 51.3819V25.6554C8.2356 25.0203 8.75221 24.153 9.38732 23.7217L10.2831 23.115C10.9182 22.6837 11.4348 22.8496 11.4348 23.4847V49.2112C11.4348 49.8463 10.9182 50.7136 10.2831 51.1449Z"
          fill="#E443B7"
        />
        <path
          d="M17.3546 46.0681L16.7858 46.5894C16.0749 47.2435 15.3924 47.0634 15.2644 46.1866L12.1031 24.2234C11.9751 23.3465 12.4538 22.1 13.1648 21.446L13.7335 20.9246C14.4445 20.2705 15.127 20.4506 15.2549 21.3275L18.4162 43.2907C18.5442 44.1675 18.0655 45.414 17.3546 46.0681Z"
          fill="#06BAF3"
        />
        <path
          d="M159.229 -46.4062V-7.19094L92.401 39.219V-1.09585L159.229 -46.4062Z"
          fill="#B88C62"
        />
        <path
          d="M19.8855 48.0703V89.5748L-11.3957 111.296L-11.5 69.3511L19.8855 48.0703Z"
          fill="#B88C62"
        />
        <path
          d="M19.8855 6.57812V14.9624L-14.922 38.921V30.2666L19.8855 6.57812Z"
          fill="#B88C62"
        />
        <path
          d="M36.4739 49.1773V-91.7969"
          stroke="#8C6239"
          stroke-width="2.36979"
          stroke-miterlimit="10"
        />
        <path
          d="M48.0054 -12.1172L25.099 4.59459"
          stroke="#8C6239"
          stroke-width="2.36979"
          stroke-miterlimit="10"
        />
        <path
          d="M48.0054 4.17969L25.099 20.8915"
          stroke="#8C6239"
          stroke-width="2.36979"
          stroke-miterlimit="10"
        />
        <path
          d="M48.0054 20.4688L25.099 37.1805"
          stroke="#8C6239"
          stroke-width="2.36979"
          stroke-miterlimit="10"
        />
        <path
          d="M92.4011 39.2552L84.8177 44.4688V-119.521L92.4011 -124.734V39.2552Z"
          fill="#B88C62"
        />
        <path
          d="M27.4687 84.2826L19.8854 89.4961V-74.4935L27.4687 -79.707V84.2826Z"
          fill="#B88C62"
        />
        <path
          d="M84.8176 44.47H51.5173V-96.215L84.8176 -119.52V44.47Z"
          fill="#8C6239"
        />
        <path
          d="M51.5174 44.2887L27.4688 61.1142V51.872L46.4271 38.6012V-92.6853L51.6406 -96.2969L51.5174 44.2887Z"
          fill="#8C6239"
        />
        <path
          opacity="0.32"
          d="M235.328 -200.25L74.4569 -198.132L321.332 78.8307L297.118 94.8695L393.734 109.344L277.141 113.833L191.942 170.542L289.335 103.292L48.2233 -197.786L-4.45712 -197.094L-20.875 -145.906L191.942 170.542L405.214 172.381L405.687 166.835V281.922H-44.099V-207.203H405.687V22.951L235.328 -200.25Z"
          fill="#181C26"
        />
        <path
          opacity="0.32"
          d="M405.687 119.074V138.843L293.596 126.312L405.687 119.074Z"
          fill="#181C26"
        />
        <path
          opacity="0.32"
          d="M289.335 103.292L191.942 170.542L-20.875 -145.906L-4.45708 -197.093L48.2234 -197.785L289.335 103.292Z"
          fill="url(#paint0_linear_690_94481)"
        />
        <path
          opacity="0.32"
          d="M405.687 22.9512L321.332 78.8309L74.4569 -198.131L235.328 -200.25L405.687 22.9512Z"
          fill="url(#paint1_linear_690_94481)"
        />
      </g>
      <path
        d="M17.2787 241.4C17.2787 241.4 5.90373 239.505 3.53394 249.932"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M102.496 139.113H81.6376"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M50.5743 101.586H44.4982V143.692C44.4982 155.323 53.9252 164.746 65.5514 164.746V129.644C65.5514 126.943 66.3145 124.417 67.6416 122.274C70.1109 118.279 74.5329 115.62 79.5711 115.62H102.743V101.586H50.5743Z"
        fill="#FF661A"
      />
      <path
        d="M114.677 101.586C114.677 106.695 113.307 111.487 110.923 115.62H102.748V101.586H114.677Z"
        fill="white"
      />
      <path
        d="M65.5514 94.5664H44.4982V101.586H65.5514V94.5664Z"
        fill="#BC5FDD"
      />
      <path
        d="M58.5321 94.5651H44.4982V80.5312L58.5321 94.5651Z"
        fill="#FF661A"
      />
      <path
        d="M72.5707 94.5651H86.6046V80.5312L72.5707 94.5651Z"
        fill="#FF661A"
      />
      <path
        d="M44.4982 91.0586V98.0779C40.626 98.0779 37.4789 94.9356 37.4789 91.0586H44.4982Z"
        fill="#BC5FDD"
      />
      <path
        d="M44.4982 98.0742V105.094H37.4789C37.4789 101.221 40.6212 98.0742 44.4982 98.0742Z"
        fill="#BC5FDD"
      />
      <path
        d="M110.923 115.618C106.065 124.012 96.9938 129.652 86.6046 129.652V143.686C86.6046 155.317 77.1776 164.739 65.5514 164.739V129.638C65.5514 126.936 66.3145 124.41 67.6415 122.268C70.1109 118.272 74.5329 115.613 79.5711 115.613H110.919L110.923 115.618Z"
        fill="#FFE9CF"
      />
      <path
        d="M114.677 101.586C114.677 106.695 113.307 111.487 110.923 115.62H102.748V101.586H114.677Z"
        fill="#3F2214"
      />
      <path
        d="M86.6046 94.5664H65.5514V101.586H86.6046V94.5664Z"
        fill="#A14ABF"
      />
      <path
        d="M60.6412 110.095C60.6412 110.095 65.3097 106.735 70.4664 110.095"
        stroke="#3F2214"
        stroke-width="2.80583"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M47.6121 139.027C47.6121 139.027 33.8673 151.35 52.3516 152.298"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M77.4714 175.995C86.4766 171.255 77.4714 165.094 77.4714 165.094"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M46.2518 162.992L40.9767 172.68"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M191.601 159.965H170.742"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M139.678 122.438H133.602V164.544C133.602 176.175 143.029 185.597 154.656 185.597V150.496C154.656 147.794 155.419 145.268 156.746 143.126C159.215 139.13 163.637 136.471 168.675 136.471H191.847V122.438H139.678Z"
        fill="#FF661A"
      />
      <path
        d="M203.781 122.438C203.781 127.547 202.412 132.338 200.028 136.471H191.852V122.438H203.781Z"
        fill="white"
      />
      <path
        d="M154.656 115.418H133.602V122.437H154.656V115.418Z"
        fill="#FFCD1A"
      />
      <path
        d="M147.636 115.421H133.602V101.387L147.636 115.421Z"
        fill="#FF661A"
      />
      <path
        d="M161.675 115.421H175.709V101.387L161.675 115.421Z"
        fill="#FF661A"
      />
      <path
        d="M133.602 111.91V118.929C129.73 118.929 126.583 115.787 126.583 111.91H133.602Z"
        fill="#FFCD1A"
      />
      <path
        d="M133.602 118.926V125.945H126.583C126.583 122.073 129.725 118.926 133.602 118.926Z"
        fill="#FFCD1A"
      />
      <path
        d="M200.028 136.473C195.169 144.867 186.098 150.507 175.709 150.507V164.541C175.709 176.172 166.282 185.595 154.656 185.595V150.493C154.656 147.792 155.419 145.265 156.746 143.123C159.215 139.128 163.637 136.469 168.675 136.469H200.023L200.028 136.473Z"
        fill="#FFE9CF"
      />
      <path
        d="M203.781 122.438C203.781 127.547 202.412 132.338 200.028 136.471H191.852V122.438H203.781Z"
        fill="#3F2214"
      />
      <path
        d="M175.709 115.418H154.656V122.437H175.709V115.418Z"
        fill="#F2A200"
      />
      <path
        d="M149.745 130.951C149.745 130.951 154.414 127.59 159.571 130.951"
        stroke="#3F2214"
        stroke-width="2.80583"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M136.716 159.883C136.716 159.883 122.971 172.206 141.456 173.154"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M166.576 196.85C175.581 192.111 166.576 185.949 166.576 185.949"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M135.356 183.844L130.081 193.531"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M282.601 181.293H261.742"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M230.678 143.766H224.602V185.872C224.602 197.503 234.029 206.925 245.655 206.925V171.824C245.655 169.122 246.418 166.596 247.746 164.454C250.215 160.458 254.637 157.8 259.675 157.8H282.847V143.766H230.678Z"
        fill="#FF661A"
      />
      <path
        d="M294.781 143.766C294.781 148.875 293.412 153.667 291.028 157.8H282.852V143.766H294.781Z"
        fill="white"
      />
      <path
        d="M245.655 136.746H224.602V143.765H245.655V136.746Z"
        fill="#18B76B"
      />
      <path
        d="M238.636 136.749H224.602V122.715L238.636 136.749Z"
        fill="#FF661A"
      />
      <path
        d="M252.675 136.749H266.709V122.715L252.675 136.749Z"
        fill="#FF661A"
      />
      <path
        d="M224.602 133.238V140.258C220.73 140.258 217.583 137.115 217.583 133.238H224.602Z"
        fill="#18B76B"
      />
      <path
        d="M224.602 140.254V147.273H217.583C217.583 143.401 220.725 140.254 224.602 140.254Z"
        fill="#18B76B"
      />
      <path
        d="M291.028 157.802C286.169 166.195 277.098 171.836 266.709 171.836V185.869C266.709 197.5 257.282 206.923 245.656 206.923V171.821C245.656 169.12 246.419 166.594 247.746 164.451C250.215 160.456 254.637 157.797 259.675 157.797H291.023L291.028 157.802Z"
        fill="#FFE9CF"
      />
      <path
        d="M294.781 143.766C294.781 148.875 293.412 153.667 291.028 157.8H282.852V143.766H294.781Z"
        fill="#3F2214"
      />
      <path
        d="M266.709 136.746H245.656V143.765H266.709V136.746Z"
        fill="#139456"
      />
      <path
        d="M240.745 152.279C240.745 152.279 245.414 148.918 250.571 152.279"
        stroke="#3F2214"
        stroke-width="2.80583"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M227.716 181.211C227.716 181.211 213.971 193.534 232.456 194.482"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M257.576 218.178C266.581 213.439 257.576 207.277 257.576 207.277"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M226.356 205.172L221.081 214.86"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M372.653 202.148H351.794"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M320.73 164.621H314.654V206.728C314.654 218.359 324.081 227.781 335.708 227.781V192.679C335.708 189.978 336.471 187.452 337.798 185.309C340.267 181.314 344.689 178.655 349.727 178.655H372.899V164.621H320.73Z"
        fill="#FF661A"
      />
      <path
        d="M384.833 164.621C384.833 169.73 383.464 174.522 381.08 178.655H372.904V164.621H384.833Z"
        fill="white"
      />
      <path
        d="M335.708 157.602H314.654V164.621H335.708V157.602Z"
        fill="#BC5FDD"
      />
      <path d="M328.688 157.6H314.654V143.566L328.688 157.6Z" fill="#FF661A" />
      <path d="M342.727 157.6H356.761V143.566L342.727 157.6Z" fill="#FF661A" />
      <path
        d="M314.654 154.094V161.113C310.782 161.113 307.635 157.971 307.635 154.094H314.654Z"
        fill="#BC5FDD"
      />
      <path
        d="M314.654 161.109V168.129H307.635C307.635 164.256 310.777 161.109 314.654 161.109Z"
        fill="#BC5FDD"
      />
      <path
        d="M381.08 178.653C376.222 187.047 367.15 192.687 356.761 192.687V206.721C356.761 218.352 347.334 227.774 335.708 227.774V192.673C335.708 189.971 336.471 187.445 337.798 185.303C340.267 181.307 344.689 178.648 349.727 178.648H381.075L381.08 178.653Z"
        fill="#FFE9CF"
      />
      <path
        d="M384.833 164.621C384.833 169.73 383.464 174.522 381.08 178.655H372.904V164.621H384.833Z"
        fill="#3F2214"
      />
      <path
        d="M356.761 157.602H335.708V164.621H356.761V157.602Z"
        fill="#A14ABF"
      />
      <path
        d="M330.797 173.134C330.797 173.134 335.466 169.774 340.623 173.134"
        stroke="#3F2214"
        stroke-width="2.80583"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M317.768 202.062C317.768 202.062 304.024 214.385 322.508 215.333"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M347.628 239.034C356.633 234.294 347.628 228.133 347.628 228.133"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M316.408 226.027L311.133 235.715"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M355.116 90.293H334.257"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M303.194 52.7656H297.118V94.8721C297.118 106.503 306.545 115.925 318.171 115.925V80.824C318.171 78.1224 318.934 75.5962 320.261 73.4539C322.731 69.4584 327.153 66.7995 332.191 66.7995H355.363V52.7656H303.194Z"
        fill="#FF661A"
      />
      <path
        d="M367.297 52.7656C367.297 57.8749 365.927 62.6666 363.543 66.7995H355.368V52.7656H367.297Z"
        fill="white"
      />
      <path
        d="M318.171 45.7461H297.118V52.7654H318.171V45.7461Z"
        fill="#18B76B"
      />
      <path
        d="M311.152 45.7487H297.118V31.7148L311.152 45.7487Z"
        fill="#FF661A"
      />
      <path
        d="M325.19 45.7487H339.224V31.7148L325.19 45.7487Z"
        fill="#FF661A"
      />
      <path
        d="M297.118 42.2383V49.2576C293.246 49.2576 290.099 46.1153 290.099 42.2383H297.118Z"
        fill="#18B76B"
      />
      <path
        d="M297.118 49.2539V56.2732H290.099C290.099 52.401 293.241 49.2539 297.118 49.2539Z"
        fill="#18B76B"
      />
      <path
        d="M363.543 66.8016C358.685 75.1954 349.614 80.8355 339.224 80.8355V94.8694C339.224 106.5 329.797 115.923 318.171 115.923V80.8213C318.171 78.1198 318.934 75.5936 320.261 73.4513C322.731 69.4558 327.153 66.7969 332.191 66.7969H363.538L363.543 66.8016Z"
        fill="#FFE9CF"
      />
      <path
        d="M367.297 52.7656C367.297 57.8749 365.927 62.6666 363.543 66.7995H355.368V52.7656H367.297Z"
        fill="#3F2214"
      />
      <path
        d="M339.224 45.7461H318.171V52.7654H339.224V45.7461Z"
        fill="#139456"
      />
      <path
        d="M313.261 61.2787C313.261 61.2787 317.929 57.9183 323.086 61.2787"
        stroke="#3F2214"
        stroke-width="2.80583"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M300.232 90.2109C300.232 90.2109 286.487 102.534 304.971 103.482"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M330.091 127.178C339.097 122.439 330.091 116.277 330.091 116.277"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M298.872 114.172L293.596 123.86"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M261.746 69.4414H240.888"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M209.824 31.9141H203.748V74.0205C203.748 85.6515 213.175 95.0738 224.801 95.0738V59.9724C224.801 57.2708 225.564 54.7446 226.892 52.6024C229.361 48.6069 233.783 45.948 238.821 45.948H261.993V31.9141H209.824Z"
        fill="#FF661A"
      />
      <path
        d="M273.927 31.9141C273.927 37.0233 272.557 41.815 270.173 45.948H261.998V31.9141H273.927Z"
        fill="white"
      />
      <path
        d="M224.801 24.8945H203.748V31.9139H224.801V24.8945Z"
        fill="#BC5FDD"
      />
      <path
        d="M217.782 24.8933H203.748V10.8594L217.782 24.8933Z"
        fill="#FF661A"
      />
      <path
        d="M231.821 24.8933H245.855V10.8594L231.821 24.8933Z"
        fill="#FF661A"
      />
      <path
        d="M203.748 21.3867V28.406C199.876 28.406 196.729 25.2637 196.729 21.3867H203.748Z"
        fill="#BC5FDD"
      />
      <path
        d="M203.748 28.4023V35.4217H196.729C196.729 31.5494 199.871 28.4023 203.748 28.4023Z"
        fill="#BC5FDD"
      />
      <path
        d="M270.173 45.9462C265.315 54.34 256.244 59.98 245.855 59.98V74.014C245.855 85.6449 236.428 95.0672 224.801 95.0672V59.9658C224.801 57.2643 225.564 54.7381 226.892 52.5958C229.361 48.6003 233.783 45.9414 238.821 45.9414H270.169L270.173 45.9462Z"
        fill="#FFE9CF"
      />
      <path
        d="M273.927 31.9141C273.927 37.0233 272.557 41.815 270.173 45.948H261.998V31.9141H273.927Z"
        fill="#3F2214"
      />
      <path
        d="M245.855 24.8945H224.801V31.9139H245.855V24.8945Z"
        fill="#A14ABF"
      />
      <path
        d="M219.891 40.4232C219.891 40.4232 224.56 37.0628 229.716 40.4232"
        stroke="#3F2214"
        stroke-width="2.80583"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M206.862 69.3555C206.862 69.3555 193.117 81.6784 211.602 82.6263"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M236.721 106.323C245.727 101.583 236.721 95.4219 236.721 95.4219"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M205.502 93.3203L200.227 103.008"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M167.903 48.1133H147.044"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M115.981 10.5859H109.904V52.6924C109.904 64.3233 119.331 73.7456 130.958 73.7456V38.6443C130.958 35.9427 131.721 33.4165 133.048 31.2742C135.517 27.2788 139.939 24.6198 144.977 24.6198H168.149V10.5859H115.981Z"
        fill="#FF661A"
      />
      <path
        d="M180.083 10.5859C180.083 15.6952 178.714 20.4869 176.33 24.6198H168.154V10.5859H180.083Z"
        fill="white"
      />
      <path
        d="M130.958 3.56641H109.904V10.5857H130.958V3.56641Z"
        fill="#FFCD1A"
      />
      <path
        d="M123.938 3.56516H109.904V-10.4688L123.938 3.56516Z"
        fill="#FF661A"
      />
      <path
        d="M137.977 3.56516H152.011V-10.4688L137.977 3.56516Z"
        fill="#FF661A"
      />
      <path
        d="M109.904 0.0585938V7.07792C106.032 7.07792 102.885 3.93557 102.885 0.0585938H109.904Z"
        fill="#FFCD1A"
      />
      <path
        d="M109.904 7.07422V14.0935H102.885C102.885 10.2213 106.027 7.07422 109.904 7.07422Z"
        fill="#FFCD1A"
      />
      <path
        d="M176.33 24.618C171.472 33.0118 162.4 38.6519 152.011 38.6519V52.6859C152.011 64.3168 142.584 73.7391 130.958 73.7391V38.6377C130.958 35.9362 131.721 33.41 133.048 31.2677C135.517 27.2722 139.939 24.6133 144.977 24.6133H176.325L176.33 24.618Z"
        fill="#FFE9CF"
      />
      <path
        d="M180.083 10.5859C180.083 15.6952 178.714 20.4869 176.33 24.6198H168.154V10.5859H180.083Z"
        fill="#3F2214"
      />
      <path
        d="M152.011 3.56641H130.958V10.5857H152.011V3.56641Z"
        fill="#F2A200"
      />
      <path
        d="M126.047 19.0951C126.047 19.0951 130.716 15.7347 135.873 19.0951"
        stroke="#3F2214"
        stroke-width="2.80583"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M113.018 48.0273C113.018 48.0273 99.2735 60.3503 117.758 61.2982"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M142.878 84.9948C151.883 80.2552 142.878 74.0938 142.878 74.0938"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M111.658 71.9922L106.383 81.6799"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M321.024 -59.0859H327.101V-16.9795C327.101 -5.34855 317.674 4.07375 306.047 4.07375V-31.0276C306.047 -33.7292 305.284 -36.2554 303.957 -38.3977C301.488 -42.3931 297.066 -45.052 292.028 -45.052H268.856V-59.0859H321.024Z"
        fill="#FF661A"
      />
      <path
        d="M260.68 -45.0539C265.538 -36.6601 274.61 -31.0199 284.999 -31.0199V-16.986C284.999 -5.35511 294.426 4.06719 306.052 4.06719V-31.0342C306.052 -33.7357 305.289 -36.2619 303.962 -38.4042C301.493 -42.3997 297.071 -45.0586 292.032 -45.0586H260.685L260.68 -45.0539Z"
        fill="#FFE9CF"
      />
      <path
        d="M293.123 9.16406H299.284"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M309.237 12.0078H316.346"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M82.922 204.906C82.922 210.016 81.5522 214.807 79.1682 218.94H70.9924V204.906H82.922Z"
        fill="white"
      />
      <path
        d="M33.7962 197.887H12.7429V204.906H33.7962V197.887Z"
        fill="#EAECF0"
      />
      <path
        d="M26.7768 197.889H12.7429V183.855L26.7768 197.889Z"
        fill="#FF661A"
      />
      <path
        d="M40.8156 197.889H54.8495V183.855L40.8156 197.889Z"
        fill="#FF661A"
      />
      <path
        d="M12.743 194.379V201.398C8.87071 201.398 5.72363 198.256 5.72363 194.379H12.743Z"
        fill="#EAECF0"
      />
      <path
        d="M12.743 201.395V208.414H5.72363C5.72363 204.542 8.86598 201.395 12.743 201.395Z"
        fill="#EAECF0"
      />
      <path
        d="M70.9924 204.907V218.941H56.9159C49.1903 218.941 42.9246 225.207 42.9246 232.932V265.986C40.1662 267.318 37.0712 268.062 33.8009 268.062C22.17 268.062 12.7477 258.635 12.7477 247.009V204.902H70.9972L70.9924 204.907Z"
        fill="#FF661A"
      />
      <path
        d="M82.922 204.906C82.922 210.016 81.5522 214.807 79.1682 218.94H70.9924V204.906H82.922Z"
        fill="#3F2214"
      />
      <path
        d="M54.8494 197.887H33.7961V204.906H54.8494V197.887Z"
        fill="#D3D6DE"
      />
      <path
        d="M42.0336 215.12C42.0336 215.12 45.6878 210.675 51.5317 212.608"
        stroke="#3F2214"
        stroke-width="2.80583"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M27.9475 272.211H23.4402"
        stroke="#723621"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M79.1682 218.941C74.3102 227.335 65.2386 232.975 54.8495 232.975V247.009C54.8495 255.365 49.9772 262.588 42.9199 265.987V232.933C42.9199 225.207 49.1856 218.941 56.9112 218.941H79.1635H79.1682Z"
        fill="#FFE9CF"
      />
      <path
        d="M62.3048 252.303C59.935 241.402 48.0861 241.402 48.0861 241.402"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M52.3516 272.21L42.9293 271.527"
        stroke="#AA4B22"
        stroke-width="7.01932"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_690_94481"
        x1="104.013"
        y1="-63.4464"
        x2="222.697"
        y2="110.601"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.42" stop-color="#8A8A8A" />
        <stop offset="0.93" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_690_94481"
        x1="189.975"
        y1="-157.186"
        x2="402.299"
        y2="97.0404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.42" stop-color="#8A8A8A" />
        <stop offset="0.93" />
      </linearGradient>
      <clipPath id="clip0_690_94481">
        <rect width="375" height="277" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
