<template>
  <svg
    width="100%"
    viewBox="0 0 960 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_418_31270"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="108"
      y="68"
      width="852"
      height="929"
    >
      <path
        d="M215.78 68H960V997H215.78C156.3 997 108 948.71 108 889.22V175.78C108 116.3 156.29 68 215.78 68Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_418_31270)">
      <path
        d="M392 723.08V523L737 65L972.41 75.73L971.79 471.69L392 723.08Z"
        fill="#A67C52"
      />
      <path
        d="M138 738.88V553.29L292 483.5L354.32 538.9L138 738.88Z"
        fill="#754C24"
      />
      <path d="M971 332H758V506H971V332Z" fill="#754C24" />
      <path
        d="M63.67 671.28L166 605.11V284L65 343.87L63.67 671.28Z"
        fill="#603813"
      />
      <path
        d="M302.22 509.289L460.5 395.739L746.63 396.939L742.93 75.7295H459.38L302.22 184.959V509.289Z"
        fill="#603813"
      />
      <path
        d="M971.5 1059.5H63.67V718.63L468 438H971.5V1059.5Z"
        fill="#FFBB6B"
      />
      <path
        d="M600.5 428.5L43.5 818.5"
        stroke="#8C6239"
        stroke-width="8"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M823 438L26.5 996.5"
        stroke="#8C6239"
        stroke-width="8"
        stroke-miterlimit="10"
      />
      <path
        d="M187.5 1071.5L971.5 524.5"
        stroke="#8C6239"
        stroke-width="8"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M480.5 1069.5L971.5 721.5"
        stroke="#8C6239"
        stroke-width="8"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M738.5 1068.5L971.5 907.5"
        stroke="#8C6239"
        stroke-width="8"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path d="M452 366.26H340.53L452 284V366.26Z" fill="#42210B" />
      <path d="M158 566H46.53L158 483.74V566Z" fill="#42210B" />
      <path d="M158 478H46.53L158 395.74V478Z" fill="#42210B" />
      <path d="M158 385H46.53L158 302.74V385Z" fill="#42210B" />
      <path d="M452 278H332L452 195.74V278Z" fill="#42210B" />
      <path d="M452 185H323L452 92V185Z" fill="#42210B" />
      <path d="M468 356L574.04 284H468V356Z" fill="#42210B" />
      <path d="M468 271L588 192H468V271Z" fill="#42210B" />
      <path d="M468 178L599 91.7598H468V178Z" fill="#42210B" />
      <path
        d="M169 588.48L225 547.88L224.5 250.7L163.37 292.51L169 588.48Z"
        fill="#FFE9CF"
      />
      <path
        d="M482.89 289H477.11C474.84 289 473 290.84 473 293.11V349.89C473 352.16 474.84 354 477.11 354H482.89C485.16 354 487 352.16 487 349.89V293.11C487 290.84 485.16 289 482.89 289Z"
        fill="#18B76B"
      />
      <path
        d="M728.89 289H723.11C720.84 289 719 290.84 719 293.11V349.89C719 352.16 720.84 354 723.11 354H728.89C731.16 354 733 352.16 733 349.89V293.11C733 290.84 731.16 289 728.89 289Z"
        fill="#18B76B"
      />
      <path
        d="M500.446 288.897L494.72 289.683C492.471 289.992 490.899 292.065 491.208 294.314L498.933 350.566C499.242 352.815 501.315 354.387 503.564 354.079L509.29 353.292C511.539 352.983 513.112 350.91 512.803 348.661L505.077 292.409C504.769 290.16 502.695 288.588 500.446 288.897Z"
        fill="#06BAF3"
      />
      <path
        d="M519.45 288.891L513.724 289.677C511.475 289.986 509.902 292.059 510.211 294.308L517.937 350.56C518.245 352.809 520.319 354.381 522.568 354.073L528.294 353.286C530.543 352.977 532.115 350.904 531.806 348.655L524.081 292.403C523.772 290.154 521.699 288.582 519.45 288.891Z"
        fill="#BC5FDD"
      />
      <path
        d="M545.472 288.07L533.722 289.684C531.473 289.993 529.901 292.066 530.209 294.315L537.935 350.567C538.244 352.816 540.317 354.388 542.566 354.079L554.316 352.466C556.564 352.157 558.137 350.084 557.828 347.835L550.103 291.583C549.794 289.334 547.721 287.761 545.472 288.07Z"
        fill="#BC5FDD"
      />
      <path
        d="M484.442 200.892L478.716 201.678C476.467 201.987 474.894 204.06 475.203 206.309L482.929 262.561C483.237 264.81 485.311 266.382 487.56 266.074L493.286 265.287C495.535 264.978 497.107 262.905 496.798 260.656L489.073 204.404C488.764 202.155 486.691 200.583 484.442 200.892Z"
        fill="#18B76B"
      />
      <path
        d="M510.464 200.071L498.714 201.685C496.465 201.994 494.893 204.067 495.202 206.316L502.927 262.568C503.236 264.817 505.309 266.389 507.558 266.08L519.308 264.467C521.557 264.158 523.129 262.085 522.82 259.836L515.095 203.584C514.786 201.335 512.713 199.762 510.464 200.071Z"
        fill="#BC5FDD"
      />
      <path
        d="M703.472 288.062L691.723 289.676C689.474 289.985 687.901 292.058 688.21 294.307L695.936 350.559C696.245 352.808 698.318 354.381 700.567 354.072L712.316 352.458C714.565 352.149 716.138 350.076 715.829 347.827L708.103 291.575C707.795 289.326 705.721 287.754 703.472 288.062Z"
        fill="#BC5FDD"
      />
      <path
        d="M578.94 288.59H567.08C564.81 288.59 562.97 290.43 562.97 292.7V349.48C562.97 351.75 564.81 353.59 567.08 353.59H578.94C581.21 353.59 583.05 351.75 583.05 349.48V292.7C583.05 290.43 581.21 288.59 578.94 288.59Z"
        fill="#06BAF3"
      />
      <path
        d="M620.89 288.59H619.08C616.81 288.59 614.97 290.43 614.97 292.7V349.48C614.97 351.75 616.81 353.59 619.08 353.59H620.89C623.16 353.59 625 351.75 625 349.48V292.7C625 290.43 623.16 288.59 620.89 288.59Z"
        fill="#35D4A5"
      />
      <path
        d="M635.89 288.59H634.08C631.81 288.59 629.97 290.43 629.97 292.7V349.48C629.97 351.75 631.81 353.59 634.08 353.59H635.89C638.16 353.59 640 351.75 640 349.48V292.7C640 290.43 638.16 288.59 635.89 288.59Z"
        fill="#E443B7"
      />
      <path
        d="M651.89 288.59H650.08C647.81 288.59 645.97 290.43 645.97 292.7V349.48C645.97 351.75 647.81 353.59 650.08 353.59H651.89C654.16 353.59 656 351.75 656 349.48V292.7C656 290.43 654.16 288.59 651.89 288.59Z"
        fill="#E443B7"
      />
      <path
        d="M663.188 288.976L661.405 289.292C659.17 289.688 657.68 291.821 658.076 294.056L667.984 349.964C668.38 352.2 670.513 353.69 672.748 353.294L674.531 352.978C676.766 352.582 678.256 350.449 677.86 348.214L667.952 292.305C667.556 290.07 665.423 288.58 663.188 288.976Z"
        fill="#06BAF3"
      />
      <path
        d="M678.187 288.978L676.405 289.294C674.17 289.69 672.679 291.823 673.075 294.058L682.984 349.966C683.38 352.202 685.513 353.692 687.748 353.296L689.53 352.98C691.765 352.584 693.256 350.451 692.86 348.216L682.952 292.307C682.555 290.072 680.422 288.582 678.187 288.978Z"
        fill="#06BAF3"
      />
      <path
        d="M604.94 288.59H593.08C590.81 288.59 588.97 290.43 588.97 292.7V349.48C588.97 351.75 590.81 353.59 593.08 353.59H604.94C607.21 353.59 609.05 351.75 609.05 349.48V292.7C609.05 290.43 607.21 288.59 604.94 288.59Z"
        fill="#35D4A5"
      />
      <path
        d="M277.03 480.27L274.39 482.06C272.52 483.33 271 482.84 271 480.97V428.61C271 426.74 272.52 424.19 274.39 422.92L277.03 421.13C278.9 419.86 280.42 420.35 280.42 422.22V474.58C280.42 476.45 278.9 479 277.03 480.27Z"
        fill="#18B76B"
      />
      <path
        d="M442.61 367.96L439.97 369.75C438.1 371.02 436.58 370.53 436.58 368.66V316.3C436.58 314.43 438.1 311.88 439.97 310.61L442.61 308.82C444.48 307.55 446 308.04 446 309.91V362.27C446 364.14 444.48 366.69 442.61 367.96Z"
        fill="#BC5FDD"
      />
      <path
        d="M295.42 467.17L291.57 470.5C290.06 471.81 288.66 471.32 288.45 469.42L283.25 421.77C283.04 419.86 284.1 417.26 285.61 415.95L289.46 412.62C290.97 411.31 292.37 411.8 292.58 413.7L297.78 461.35C297.99 463.26 296.93 465.86 295.42 467.17Z"
        fill="#06BAF3"
      />
      <path
        d="M308.21 458.49L304.36 461.82C302.85 463.13 301.45 462.64 301.24 460.74L296.04 413.09C295.83 411.18 296.89 408.58 298.4 407.27L302.25 403.94C303.76 402.63 305.16 403.12 305.37 405.02L310.57 452.67C310.78 454.58 309.72 457.18 308.21 458.49Z"
        fill="#18B76B"
      />
      <path
        d="M325.73 445.859L317.82 452.689C316.31 453.999 314.91 453.509 314.7 451.609L309.5 403.959C309.29 402.049 310.35 399.449 311.86 398.139L319.77 391.309C321.28 389.999 322.68 390.489 322.89 392.389L328.09 440.039C328.3 441.949 327.24 444.549 325.73 445.859Z"
        fill="#18B76B"
      />
      <path
        d="M432.07 373.72L424.16 380.55C422.65 381.86 421.25 381.37 421.04 379.47L415.84 331.82C415.63 329.91 416.69 327.31 418.2 326L426.11 319.17C427.62 317.86 429.02 318.35 429.23 320.25L434.43 367.9C434.64 369.81 433.58 372.41 432.07 373.72Z"
        fill="#E443B7"
      />
      <path
        d="M340.96 436.53L335.66 440.12C333.39 441.66 331.55 441.07 331.55 438.8V387.88C331.55 385.61 333.39 382.52 335.66 380.98L340.96 377.39C343.23 375.85 345.07 376.44 345.07 378.71V429.63C345.07 431.9 343.23 434.99 340.96 436.53Z"
        fill="#06BAF3"
      />
      <path
        d="M370.88 416.24L368.99 417.52C367.65 418.43 366.56 418.08 366.56 416.74V362.46C366.56 361.12 367.65 359.29 368.99 358.38L370.88 357.1C372.22 356.19 373.31 356.54 373.31 357.88V412.16C373.31 413.5 372.22 415.33 370.88 416.24Z"
        fill="#35D4A5"
      />
      <path
        d="M380.97 409.39L379.08 410.67C377.74 411.58 376.65 411.23 376.65 409.89V355.61C376.65 354.27 377.74 352.44 379.08 351.53L380.97 350.25C382.31 349.34 383.4 349.69 383.4 351.03V405.31C383.4 406.65 382.31 408.48 380.97 409.39Z"
        fill="#18B76B"
      />
      <path
        d="M391.74 402.09L389.85 403.37C388.51 404.28 387.42 403.93 387.42 402.59V348.31C387.42 346.97 388.51 345.14 389.85 344.23L391.74 342.95C393.08 342.04 394.17 342.39 394.17 343.73V398.01C394.17 399.35 393.08 401.18 391.74 402.09Z"
        fill="#E443B7"
      />
      <path
        d="M406.66 391.37L405.46 392.47C403.96 393.85 402.52 393.47 402.25 391.62L395.58 345.28C395.31 343.43 396.32 340.8 397.82 339.42L399.02 338.32C400.52 336.94 401.96 337.32 402.23 339.17L408.9 385.51C409.17 387.36 408.16 389.99 406.66 391.37Z"
        fill="#06BAF3"
      />
      <path
        d="M416.75 384.52L415.55 385.62C414.05 387 412.61 386.62 412.34 384.77L405.67 338.43C405.4 336.58 406.41 333.95 407.91 332.57L409.11 331.47C410.61 330.09 412.05 330.47 412.32 332.32L418.99 378.66C419.26 380.51 418.25 383.14 416.75 384.52Z"
        fill="#BC5FDD"
      />
      <path
        d="M358.46 424.66L353.16 428.25C350.89 429.79 349.05 429.2 349.05 426.93V376.01C349.05 373.74 350.89 370.65 353.16 369.11L358.46 365.52C360.73 363.98 362.57 364.57 362.57 366.84V417.76C362.57 420.03 360.73 423.12 358.46 424.66Z"
        fill="#35D4A5"
      />
      <path
        d="M277.03 393.27L274.39 395.06C272.52 396.33 271 395.84 271 393.97V341.61C271 339.74 272.52 337.19 274.39 335.92L277.03 334.13C278.9 332.86 280.42 333.35 280.42 335.22V387.58C280.42 389.45 278.9 392 277.03 393.27Z"
        fill="#18B76B"
      />
      <path
        d="M442.61 280.96L439.97 282.75C438.1 284.02 436.58 283.53 436.58 281.66V229.3C436.58 227.43 438.1 224.88 439.97 223.61L442.61 221.82C444.48 220.55 446 221.04 446 222.91V275.27C446 277.14 444.48 279.69 442.61 280.96Z"
        fill="#18B76B"
      />
      <path
        d="M295.42 380.17L291.57 383.5C290.06 384.81 288.66 384.32 288.45 382.42L283.25 334.77C283.04 332.86 284.1 330.26 285.61 328.95L289.46 325.62C290.97 324.31 292.37 324.8 292.58 326.7L297.78 374.35C297.99 376.26 296.93 378.86 295.42 380.17Z"
        fill="#06BAF3"
      />
      <path
        d="M308.21 371.49L304.36 374.82C302.85 376.13 301.45 375.64 301.24 373.74L296.04 326.09C295.83 324.18 296.89 321.58 298.4 320.27L302.25 316.94C303.76 315.63 305.16 316.12 305.37 318.02L310.57 365.67C310.78 367.58 309.72 370.18 308.21 371.49Z"
        fill="#BC5FDD"
      />
      <path
        d="M325.73 358.859L317.82 365.689C316.31 366.999 314.91 366.509 314.7 364.609L309.5 316.959C309.29 315.049 310.35 312.449 311.86 311.139L319.77 304.309C321.28 302.999 322.68 303.489 322.89 305.389L328.09 353.039C328.3 354.949 327.24 357.549 325.73 358.859Z"
        fill="#BC5FDD"
      />
      <path
        d="M432.07 286.72L424.16 293.55C422.65 294.86 421.25 294.37 421.04 292.47L415.84 244.82C415.63 242.91 416.69 240.31 418.2 239L426.11 232.17C427.62 230.86 429.02 231.35 429.23 233.25L434.43 280.9C434.64 282.81 433.58 285.41 432.07 286.72Z"
        fill="#BC5FDD"
      />
      <path
        d="M340.96 349.53L335.66 353.12C333.39 354.66 331.55 354.07 331.55 351.8V300.88C331.55 298.61 333.39 295.52 335.66 293.98L340.96 290.39C343.23 288.85 345.07 289.44 345.07 291.71V342.63C345.07 344.9 343.23 347.99 340.96 349.53Z"
        fill="#06BAF3"
      />
      <path
        d="M370.88 329.24L368.99 330.52C367.65 331.43 366.56 331.08 366.56 329.74V275.46C366.56 274.12 367.65 272.29 368.99 271.38L370.88 270.1C372.22 269.19 373.31 269.54 373.31 270.88V325.16C373.31 326.5 372.22 328.33 370.88 329.24Z"
        fill="#18B76B"
      />
      <path
        d="M380.97 322.39L379.08 323.67C377.74 324.58 376.65 324.23 376.65 322.89V268.61C376.65 267.27 377.74 265.44 379.08 264.53L380.97 263.25C382.31 262.34 383.4 262.69 383.4 264.03V318.31C383.4 319.65 382.31 321.48 380.97 322.39Z"
        fill="#35D4A5"
      />
      <path
        d="M391.74 315.09L389.85 316.37C388.51 317.28 387.42 316.93 387.42 315.59V261.31C387.42 259.97 388.51 258.14 389.85 257.23L391.74 255.95C393.08 255.04 394.17 255.39 394.17 256.73V311.01C394.17 312.35 393.08 314.18 391.74 315.09Z"
        fill="#18B76B"
      />
      <path
        d="M406.66 304.37L405.46 305.47C403.96 306.85 402.52 306.47 402.25 304.62L395.58 258.28C395.31 256.43 396.32 253.8 397.82 252.42L399.02 251.32C400.52 249.94 401.96 250.32 402.23 252.17L408.9 298.51C409.17 300.36 408.16 302.99 406.66 304.37Z"
        fill="#06BAF3"
      />
      <path
        d="M416.75 297.52L415.55 298.62C414.05 300 412.61 299.62 412.34 297.77L405.67 251.43C405.4 249.58 406.41 246.95 407.91 245.57L409.11 244.47C410.61 243.09 412.05 243.47 412.32 245.32L418.99 291.66C419.26 293.51 418.25 296.14 416.75 297.52Z"
        fill="#06BAF3"
      />
      <path
        d="M358.46 337.66L353.16 341.25C350.89 342.79 349.05 342.2 349.05 339.93V289.01C349.05 286.74 350.89 283.65 353.16 282.11L358.46 278.52C360.73 276.98 362.57 277.57 362.57 279.84V330.76C362.57 333.03 360.73 336.12 358.46 337.66Z"
        fill="#35D4A5"
      />
      <path
        d="M274.03 297.27L271.39 299.06C269.52 300.33 268 299.84 268 297.97V245.61C268 243.74 269.52 241.19 271.39 239.92L274.03 238.13C275.9 236.86 277.42 237.35 277.42 239.22V291.58C277.42 293.45 275.9 296 274.03 297.27Z"
        fill="#18B76B"
      />
      <path
        d="M439.61 184.96L436.97 186.75C435.1 188.02 433.58 187.53 433.58 185.66V133.3C433.58 131.43 435.1 128.88 436.97 127.61L439.61 125.82C441.48 124.55 443 125.04 443 126.91V179.27C443 181.14 441.48 183.69 439.61 184.96Z"
        fill="#18B76B"
      />
      <path
        d="M292.42 284.17L288.57 287.5C287.06 288.81 285.66 288.32 285.45 286.42L280.25 238.77C280.04 236.86 281.1 234.26 282.61 232.95L286.46 229.62C287.97 228.31 289.37 228.8 289.58 230.7L294.78 278.35C294.99 280.26 293.93 282.86 292.42 284.17Z"
        fill="#06BAF3"
      />
      <path
        d="M305.21 275.49L301.36 278.82C299.85 280.13 298.45 279.64 298.24 277.74L293.04 230.09C292.83 228.18 293.89 225.58 295.4 224.27L299.25 220.94C300.76 219.63 302.16 220.12 302.37 222.02L307.57 269.67C307.78 271.58 306.72 274.18 305.21 275.49Z"
        fill="#18B76B"
      />
      <path
        d="M322.73 262.859L314.82 269.689C313.31 270.999 311.91 270.509 311.7 268.609L306.5 220.959C306.29 219.049 307.35 216.449 308.86 215.139L316.77 208.309C318.28 206.999 319.68 207.489 319.89 209.389L325.09 257.039C325.3 258.949 324.24 261.549 322.73 262.859Z"
        fill="#18B76B"
      />
      <path
        d="M429.07 190.72L421.16 197.55C419.65 198.86 418.25 198.37 418.04 196.47L412.84 148.82C412.63 146.91 413.69 144.31 415.2 143L423.11 136.17C424.62 134.86 426.02 135.35 426.23 137.25L431.43 184.9C431.64 186.81 430.58 189.41 429.07 190.72Z"
        fill="#BC5FDD"
      />
      <path
        d="M337.96 253.53L332.66 257.12C330.39 258.66 328.55 258.07 328.55 255.8V204.88C328.55 202.61 330.39 199.52 332.66 197.98L337.96 194.39C340.23 192.85 342.07 193.44 342.07 195.71V246.63C342.07 248.9 340.23 251.99 337.96 253.53Z"
        fill="#18B76B"
      />
      <path
        d="M367.88 233.24L365.99 234.52C364.65 235.43 363.56 235.08 363.56 233.74V179.46C363.56 178.12 364.65 176.29 365.99 175.38L367.88 174.1C369.22 173.19 370.31 173.54 370.31 174.88V229.16C370.31 230.5 369.22 232.33 367.88 233.24Z"
        fill="#35D4A5"
      />
      <path
        d="M377.97 226.39L376.08 227.67C374.74 228.58 373.65 228.23 373.65 226.89V172.61C373.65 171.27 374.74 169.44 376.08 168.53L377.97 167.25C379.31 166.34 380.4 166.69 380.4 168.03V222.31C380.4 223.65 379.31 225.48 377.97 226.39Z"
        fill="#E443B7"
      />
      <path
        d="M388.74 219.09L386.85 220.37C385.51 221.28 384.42 220.93 384.42 219.59V165.31C384.42 163.97 385.51 162.14 386.85 161.23L388.74 159.95C390.08 159.04 391.17 159.39 391.17 160.73V215.01C391.17 216.35 390.08 218.18 388.74 219.09Z"
        fill="#35D4A5"
      />
      <path
        d="M403.66 208.37L402.46 209.47C400.96 210.85 399.52 210.47 399.25 208.62L392.58 162.28C392.31 160.43 393.32 157.8 394.82 156.42L396.02 155.32C397.52 153.94 398.96 154.32 399.23 156.17L405.9 202.51C406.17 204.36 405.16 206.99 403.66 208.37Z"
        fill="#35D4A5"
      />
      <path
        d="M413.75 201.52L412.55 202.62C411.05 204 409.61 203.62 409.34 201.77L402.67 155.43C402.4 153.58 403.41 150.95 404.91 149.57L406.11 148.47C407.61 147.09 409.05 147.47 409.32 149.32L415.99 195.66C416.26 197.51 415.25 200.14 413.75 201.52Z"
        fill="#06BAF3"
      />
      <path
        d="M355.46 241.66L350.16 245.25C347.89 246.79 346.05 246.2 346.05 243.93V193.01C346.05 190.74 347.89 187.65 350.16 186.11L355.46 182.52C357.73 180.98 359.57 181.57 359.57 183.84V234.76C359.57 237.03 357.73 240.12 355.46 241.66Z"
        fill="#35D4A5"
      />
      <path
        d="M147.61 383.96L144.97 385.75C143.1 387.02 141.58 386.53 141.58 384.66V332.3C141.58 330.43 143.1 327.88 144.97 326.61L147.61 324.82C149.48 323.55 151 324.04 151 325.91V378.27C151 380.14 149.48 382.69 147.61 383.96Z"
        fill="#35D4A5"
      />
      <path
        d="M137.07 389.72L129.16 396.55C127.65 397.86 126.25 397.37 126.04 395.47L120.84 347.82C120.63 345.91 121.69 343.31 123.2 342L131.11 335.17C132.62 333.86 134.02 334.35 134.23 336.25L139.43 383.9C139.64 385.81 138.58 388.41 137.07 389.72Z"
        fill="#35D4A5"
      />
      <path
        d="M111.66 407.37L110.46 408.47C108.96 409.85 107.52 409.47 107.25 407.62L100.58 361.28C100.31 359.43 101.32 356.8 102.82 355.42L104.02 354.32C105.52 352.94 106.96 353.32 107.23 355.17L113.9 401.51C114.17 403.36 113.16 405.99 111.66 407.37Z"
        fill="#06BAF3"
      />
      <path
        d="M121.75 400.52L120.55 401.62C119.05 403 117.61 402.62 117.34 400.77L110.67 354.43C110.4 352.58 111.41 349.95 112.91 348.57L114.11 347.47C115.61 346.09 117.05 346.47 117.32 348.32L123.99 394.66C124.26 396.51 123.25 399.14 121.75 400.52Z"
        fill="#06BAF3"
      />
      <path
        d="M129.88 490.24L127.99 491.52C126.65 492.43 125.56 492.08 125.56 490.74V436.46C125.56 435.12 126.65 433.29 127.99 432.38L129.88 431.1C131.22 430.19 132.31 430.54 132.31 431.88V486.16C132.31 487.5 131.22 489.33 129.88 490.24Z"
        fill="#35D4A5"
      />
      <path
        d="M139.97 483.39L138.08 484.67C136.74 485.58 135.65 485.23 135.65 483.89V429.61C135.65 428.27 136.74 426.44 138.08 425.53L139.97 424.25C141.31 423.34 142.4 423.69 142.4 425.03V479.31C142.4 480.65 141.31 482.48 139.97 483.39Z"
        fill="#E443B7"
      />
      <path
        d="M150.74 476.09L148.85 477.37C147.51 478.28 146.42 477.93 146.42 476.59V422.31C146.42 420.97 147.51 419.14 148.85 418.23L150.74 416.95C152.08 416.04 153.17 416.39 153.17 417.73V472.01C153.17 473.35 152.08 475.18 150.74 476.09Z"
        fill="#E443B7"
      />
      <path
        d="M117.46 498.66L112.16 502.25C109.89 503.79 108.05 503.2 108.05 500.93V450.01C108.05 447.74 109.89 444.65 112.16 443.11L117.46 439.52C119.73 437.98 121.57 438.57 121.57 440.84V491.76C121.57 494.03 119.73 497.12 117.46 498.66Z"
        fill="#35D4A5"
      />
      <path
        d="M116.88 586.24L114.99 587.52C113.65 588.43 112.56 588.08 112.56 586.74V532.46C112.56 531.12 113.65 529.29 114.99 528.38L116.88 527.1C118.22 526.19 119.31 526.54 119.31 527.88V582.16C119.31 583.5 118.22 585.33 116.88 586.24Z"
        fill="#35D4A5"
      />
      <path
        d="M126.97 579.39L125.08 580.67C123.74 581.58 122.65 581.23 122.65 579.89V525.61C122.65 524.27 123.74 522.44 125.08 521.53L126.97 520.25C128.31 519.34 129.4 519.69 129.4 521.03V575.31C129.4 576.65 128.31 578.48 126.97 579.39Z"
        fill="#E443B7"
      />
      <path
        d="M137.74 572.09L135.85 573.37C134.51 574.28 133.42 573.93 133.42 572.59V518.31C133.42 516.97 134.51 515.14 135.85 514.23L137.74 512.95C139.08 512.04 140.17 512.39 140.17 513.73V568.01C140.17 569.35 139.08 571.18 137.74 572.09Z"
        fill="#E443B7"
      />
      <path
        d="M152.66 561.37L151.46 562.47C149.96 563.85 148.52 563.47 148.25 561.62L141.58 515.28C141.31 513.43 142.32 510.8 143.82 509.42L145.02 508.32C146.52 506.94 147.96 507.32 148.23 509.17L154.9 555.51C155.17 557.36 154.16 559.99 152.66 561.37Z"
        fill="#06BAF3"
      />
      <path
        d="M104.46 594.66L99.16 598.25C96.89 599.79 95.05 599.2 95.05 596.93V546.01C95.05 543.74 96.89 540.65 99.16 539.11L104.46 535.52C106.73 533.98 108.57 534.57 108.57 536.84V587.76C108.57 590.03 106.73 593.12 104.46 594.66Z"
        fill="#35D4A5"
      />
      <path
        d="M541.94 198.59H530.08C527.81 198.59 525.97 200.43 525.97 202.7V259.48C525.97 261.75 527.81 263.59 530.08 263.59H541.94C544.21 263.59 546.05 261.75 546.05 259.48V202.7C546.05 200.43 544.21 198.59 541.94 198.59Z"
        fill="#06BAF3"
      />
      <path
        d="M567.94 198.59H556.08C553.81 198.59 551.97 200.43 551.97 202.7V259.48C551.97 261.75 553.81 263.59 556.08 263.59H567.94C570.21 263.59 572.05 261.75 572.05 259.48V202.7C572.05 200.43 570.21 198.59 567.94 198.59Z"
        fill="#06BAF3"
      />
      <path
        d="M585.89 199H580.11C577.84 199 576 200.84 576 203.11V259.89C576 262.16 577.84 264 580.11 264H585.89C588.16 264 590 262.16 590 259.89V203.11C590 200.84 588.16 199 585.89 199Z"
        fill="#18B76B"
      />
      <path
        d="M603.443 198.889L597.717 199.675C595.468 199.984 593.895 202.057 594.204 204.306L601.93 260.558C602.239 262.807 604.312 264.38 606.561 264.071L612.287 263.284C614.536 262.975 616.109 260.902 615.8 258.653L608.074 202.401C607.765 200.153 605.692 198.58 603.443 198.889Z"
        fill="#06BAF3"
      />
      <path
        d="M622.446 198.894L616.72 199.68C614.471 199.989 612.899 202.062 613.208 204.311L620.933 260.563C621.242 262.812 623.315 264.384 625.564 264.076L631.29 263.289C633.539 262.98 635.112 260.907 634.803 258.658L627.077 202.406C626.769 200.157 624.695 198.585 622.446 198.894Z"
        fill="#BC5FDD"
      />
      <path
        d="M648.468 198.073L636.719 199.687C634.47 199.996 632.897 202.069 633.206 204.318L640.932 260.57C641.24 262.819 643.314 264.391 645.563 264.082L657.312 262.469C659.561 262.16 661.134 260.087 660.825 257.838L653.099 201.586C652.791 199.337 650.717 197.764 648.468 198.073Z"
        fill="#BC5FDD"
      />
      <path
        d="M681.94 198.59H670.08C667.81 198.59 665.97 200.43 665.97 202.7V259.48C665.97 261.75 667.81 263.59 670.08 263.59H681.94C684.21 263.59 686.05 261.75 686.05 259.48V202.7C686.05 200.43 684.21 198.59 681.94 198.59Z"
        fill="#06BAF3"
      />
      <path
        d="M723.89 198.59H722.08C719.81 198.59 717.97 200.43 717.97 202.7V259.48C717.97 261.75 719.81 263.59 722.08 263.59H723.89C726.16 263.59 728 261.75 728 259.48V202.7C728 200.43 726.16 198.59 723.89 198.59Z"
        fill="#E443B7"
      />
      <path
        d="M707.94 198.59H696.08C693.81 198.59 691.97 200.43 691.97 202.7V259.48C691.97 261.75 693.81 263.59 696.08 263.59H707.94C710.21 263.59 712.05 261.75 712.05 259.48V202.7C712.05 200.43 710.21 198.59 707.94 198.59Z"
        fill="#35D4A5"
      />
      <path
        d="M723.11 172.979H728.89C731.16 172.979 733 171.139 733 168.869V101.229C733 98.9596 731.16 97.1195 728.89 97.1195H723.11C720.84 97.1195 719 98.9596 719 101.229V168.869C719 171.139 720.84 172.979 723.11 172.979Z"
        fill="#18B76B"
      />
      <path
        d="M477.11 172.979H482.89C485.16 172.979 487 171.139 487 168.869V101.229C487 98.9596 485.16 97.1195 482.89 97.1195H477.11C474.84 97.1195 473 98.9596 473 101.229V168.869C473 171.139 474.84 172.979 477.11 172.979Z"
        fill="#18B76B"
      />
      <path
        d="M696.72 172.17L702.44 173.09C704.69 173.45 706.76 171.61 707.07 168.99L714.79 103.34C715.1 100.71 713.53 98.2898 711.28 97.9298L705.56 97.0098C703.31 96.6498 701.24 98.4898 700.93 101.11L693.21 166.76C692.9 169.39 694.47 171.81 696.72 172.17Z"
        fill="#06BAF3"
      />
      <path
        d="M677.72 172.17L683.44 173.09C685.69 173.45 687.76 171.61 688.07 168.99L695.79 103.34C696.1 100.71 694.53 98.2898 692.28 97.9298L686.56 97.0098C684.31 96.6498 682.24 98.4898 681.93 101.11L674.21 166.76C673.9 169.39 675.47 171.81 677.72 172.17Z"
        fill="#BC5FDD"
      />
      <path
        d="M651.69 171.2L663.44 173.08C665.69 173.44 667.76 171.6 668.07 168.98L675.79 103.33C676.1 100.7 674.53 98.2801 672.28 97.9201L660.53 96.0401C658.28 95.6801 656.21 97.5201 655.9 100.14L648.18 165.79C647.87 168.42 649.44 170.84 651.69 171.2Z"
        fill="#BC5FDD"
      />
      <path
        d="M493.69 171.2L505.44 173.08C507.69 173.44 509.76 171.6 510.07 168.98L517.79 103.33C518.1 100.7 516.53 98.2801 514.28 97.9201L502.53 96.0401C500.28 95.6801 498.21 97.5201 497.9 100.14L490.18 165.79C489.87 168.42 491.44 170.84 493.69 171.2Z"
        fill="#BC5FDD"
      />
      <path
        d="M627.05 172.5H638.91C641.18 172.5 643.02 170.66 643.02 168.39V100.75C643.02 98.4801 641.18 96.64 638.91 96.64H627.05C624.78 96.64 622.94 98.4801 622.94 100.75V168.39C622.94 170.66 624.78 172.5 627.05 172.5Z"
        fill="#18B76B"
      />
      <path
        d="M585.11 172.5H586.92C589.19 172.5 591.03 170.66 591.03 168.39V100.75C591.03 98.4801 589.19 96.64 586.92 96.64H585.11C582.84 96.64 581 98.4801 581 100.75V168.39C581 170.66 582.84 172.5 585.11 172.5Z"
        fill="#35D4A5"
      />
      <path
        d="M570.11 172.5H571.92C574.19 172.5 576.03 170.66 576.03 168.39V100.75C576.03 98.4801 574.19 96.64 571.92 96.64H570.11C567.84 96.64 566 98.4801 566 100.75V168.39C566 170.66 567.84 172.5 570.11 172.5Z"
        fill="#E443B7"
      />
      <path
        d="M554.11 172.5H555.92C558.19 172.5 560.03 170.66 560.03 168.39V100.75C560.03 98.4801 558.19 96.64 555.92 96.64H554.11C551.84 96.64 550 98.4801 550 100.75V168.39C550 170.66 551.84 172.5 554.11 172.5Z"
        fill="#06BAF3"
      />
      <path
        d="M531.45 171.73L533.23 172.1C535.47 172.56 537.6 170.82 538 168.21L547.91 102.96C548.31 100.35 546.82 97.8598 544.58 97.3998L542.8 97.0298C540.56 96.5698 538.43 98.3098 538.03 100.92L528.12 166.17C527.72 168.78 529.21 171.27 531.45 171.73Z"
        fill="#06BAF3"
      />
      <path
        d="M516.45 171.73L518.23 172.1C520.47 172.56 522.6 170.82 523 168.21L532.91 102.96C533.31 100.35 531.82 97.8598 529.58 97.3998L527.8 97.0298C525.56 96.5698 523.43 98.3098 523.03 100.92L513.12 166.17C512.72 168.78 514.21 171.27 516.45 171.73Z"
        fill="#06BAF3"
      />
      <path
        d="M601.05 172.5H612.91C615.18 172.5 617.02 170.66 617.02 168.39V100.75C617.02 98.4801 615.18 96.64 612.91 96.64H601.05C598.78 96.64 596.94 98.4801 596.94 100.75V168.39C596.94 170.66 598.78 172.5 601.05 172.5Z"
        fill="#35D4A5"
      />
      <path d="M452 366.26V449L311 546.92V461.86L452 366.26Z" fill="#B88C62" />
      <path
        d="M158 565.6V653.17L92 699L91.78 610.5L158 565.6Z"
        fill="#B88C62"
      />
      <path
        d="M452 277.96V293.37L311 390.43V373.92L452 277.96Z"
        fill="#B88C62"
      />
      <path
        d="M158 478.06V495.75L84.56 546.3V528.04L158 478.06Z"
        fill="#B88C62"
      />
      <path
        d="M452 184.96V200.37L311 297.43V280.92L452 184.96Z"
        fill="#B88C62"
      />
      <path
        d="M158 385.06V402.75L84.56 453.3V435.04L158 385.06Z"
        fill="#B88C62"
      />
      <path d="M468 355.88H737V438H468V355.88Z" fill="#B88C62" />
      <path d="M468 270.5H737V284H468V270.5Z" fill="#B88C62" />
      <path d="M468 178H737V192H468V178Z" fill="#B88C62" />
      <path d="M753 438L787.35 390V92H753V438Z" fill="#8C6239" />
      <path
        d="M193 567.94V270.5"
        stroke="#8C6239"
        stroke-width="5"
        stroke-miterlimit="10"
      />
      <path
        d="M217.33 301.109L169 336.369"
        stroke="#8C6239"
        stroke-width="5"
        stroke-miterlimit="10"
      />
      <path
        d="M217.33 335.49L169 370.75"
        stroke="#8C6239"
        stroke-width="5"
        stroke-miterlimit="10"
      />
      <path
        d="M217.33 369.859L169 405.119"
        stroke="#8C6239"
        stroke-width="5"
        stroke-miterlimit="10"
      />
      <path
        d="M217.33 404.24L169 439.5"
        stroke="#8C6239"
        stroke-width="5"
        stroke-miterlimit="10"
      />
      <path
        d="M217.33 438.609L169 473.869"
        stroke="#8C6239"
        stroke-width="5"
        stroke-miterlimit="10"
      />
      <path
        d="M217.33 472.99L169 508.25"
        stroke="#8C6239"
        stroke-width="5"
        stroke-miterlimit="10"
      />
      <path
        d="M217.33 507.359L169 542.619"
        stroke="#8C6239"
        stroke-width="5"
        stroke-miterlimit="10"
      />
      <path d="M468 438L452 449V103L468 92V438Z" fill="#B88C62" />
      <path d="M753 92H737V438H753V92Z" fill="#B88C62" />
      <path d="M311 547L295 558V212L311 201V547Z" fill="#B88C62" />
      <path d="M174 642L158 653V307L174 296V642Z" fill="#B88C62" />
      <path d="M97 350L468 91.9996H972L966 52.5596H97V350Z" fill="#B88C62" />
      <path
        d="M155 205.89V267.02C155 270.58 153.25 273.91 150.33 275.94L91.78 316.47V228.18L138.08 196.9C145.28 192.03 155 197.19 155 205.89Z"
        stroke="#8C6239"
        stroke-width="7"
        stroke-miterlimit="10"
      />
      <path
        d="M292 113.32V172.16C292 175.72 290.25 179.05 287.33 181.08L194.03 245.68C186.83 250.66 177.01 245.51 177.01 236.76V176.37C177.01 172.77 178.8 169.4 181.78 167.38L275.08 104.33C282.28 99.4596 292 104.62 292 113.32Z"
        stroke="#8C6239"
        stroke-width="7"
        stroke-miterlimit="10"
      />
      <path
        d="M446 9.26976V65.5298C446 69.0898 444.25 72.4198 441.33 74.4498L331.03 150.83C323.83 155.81 314.01 150.66 314.01 141.91V83.7998C314.01 80.1998 315.8 76.8298 318.78 74.8098L429.08 0.279755C436.28 -4.59024 446 0.569757 446 9.26976Z"
        stroke="#8C6239"
        stroke-width="7"
        stroke-miterlimit="10"
      />
      <path d="M295 558H224.74V261.17L295 212V558Z" fill="#8C6239" />
      <path
        d="M224.74 557.62L174 593.12V573.62L214 545.62V268.62L225 261L224.74 557.62Z"
        fill="#8C6239"
      />
      <path
        d="M91.78 177.39L324.5 19.5"
        stroke="#8C6239"
        stroke-width="7"
        stroke-miterlimit="10"
      />
      <path
        d="M828.32 154.85L903.21 101.96H972.41L972 178H857.64L828.32 154.85Z"
        fill="#2F3E95"
      />
      <path
        d="M919.36 313.18H828.31V374.12C828.31 377.36 830.94 379.99 834.18 379.99H919.35V313.17L919.36 313.18Z"
        fill="#4156CB"
      />
      <path
        d="M912.37 319.81L918.98 313.2L828.33 313.27V319.81H912.37Z"
        fill="#4156CB"
      />
      <path
        d="M912.37 319.81L918.98 313.2L828.33 313.27V319.81H912.37Z"
        fill="black"
      />
      <path
        d="M902.84 332.27H887.43C885.806 332.27 884.49 333.586 884.49 335.21V355.76C884.49 357.383 885.806 358.7 887.43 358.7H902.84C904.464 358.7 905.78 357.383 905.78 355.76V335.21C905.78 333.586 904.464 332.27 902.84 332.27Z"
        fill="#2F3E95"
      />
      <path
        d="M897.7 338.88H892.57C890.946 338.88 889.63 340.196 889.63 341.82V349.89C889.63 351.514 890.946 352.83 892.57 352.83H897.7C899.324 352.83 900.64 351.514 900.64 349.89V341.82C900.64 340.196 899.324 338.88 897.7 338.88Z"
        fill="#4156CB"
      />
      <path
        d="M897.7 338.88H892.57C890.946 338.88 889.63 340.196 889.63 341.82V349.89C889.63 351.514 890.946 352.83 892.57 352.83H897.7C899.324 352.83 900.64 351.514 900.64 349.89V341.82C900.64 340.196 899.324 338.88 897.7 338.88Z"
        fill="black"
      />
      <path
        d="M836.39 270.23L816.2 294.46H909.08L926.7 270.23H836.39Z"
        fill="#4156CB"
      />
      <path
        d="M836.39 270.23L816.2 294.46H909.08L926.7 270.23H836.39Z"
        fill="black"
      />
      <path
        d="M839.41 278.6L834.36 285.21C833.99 285.69 834.34 286.39 834.94 286.39H861.45C861.7 286.39 861.92 286.27 862.06 286.06L866.47 279.45C866.8 278.96 866.45 278.31 865.86 278.31H839.99C839.76 278.31 839.55 278.42 839.41 278.6Z"
        fill="#69B8F0"
      />
      <path
        d="M839.41 278.6L834.36 285.21C833.99 285.69 834.34 286.39 834.94 286.39H861.45C861.7 286.39 861.92 286.27 862.06 286.06L866.47 279.45C866.8 278.96 866.45 278.31 865.86 278.31H839.99C839.76 278.31 839.55 278.42 839.41 278.6Z"
        fill="black"
      />
      <path
        d="M866.6 279.02V276.09C866.08 276.41 865.03 277.07 864.97 277.14C864.9 277.21 864.65 278.94 864.53 279.79L866.61 279.02H866.6Z"
        fill="#69B8F0"
      />
      <path
        d="M866.6 279.02V276.09C866.08 276.41 865.03 277.07 864.97 277.14C864.9 277.21 864.65 278.94 864.53 279.79L866.61 279.02H866.6Z"
        fill="black"
      />
      <path
        d="M834.21 282.74V285.67C834.73 285.35 835.78 284.69 835.84 284.62C835.91 284.54 836.16 282.82 836.28 281.97L834.2 282.74H834.21Z"
        fill="#69B8F0"
      />
      <path
        d="M834.21 282.74V285.67C834.73 285.35 835.78 284.69 835.84 284.62C835.91 284.54 836.16 282.82 836.28 281.97L834.2 282.74H834.21Z"
        fill="black"
      />
      <path
        d="M838.75 276.53L834.36 282.27C833.99 282.75 834.34 283.45 834.94 283.45H861.45C861.7 283.45 861.92 283.33 862.06 283.12L866.47 276.51C866.8 276.02 866.45 275.37 865.86 275.37H841.08C840.16 275.37 839.3 275.8 838.75 276.52V276.53Z"
        fill="#69B8F0"
      />
      <path
        d="M878.17 278.6L873.12 285.21C872.75 285.69 873.1 286.39 873.7 286.39H900.21C900.46 286.39 900.68 286.27 900.82 286.06L905.23 279.45C905.56 278.96 905.21 278.31 904.62 278.31H878.75C878.52 278.31 878.31 278.42 878.17 278.6Z"
        fill="#69B56F"
      />
      <path
        d="M878.17 278.6L873.12 285.21C872.75 285.69 873.1 286.39 873.7 286.39H900.21C900.46 286.39 900.68 286.27 900.82 286.06L905.23 279.45C905.56 278.96 905.21 278.31 904.62 278.31H878.75C878.52 278.31 878.31 278.42 878.17 278.6Z"
        fill="black"
      />
      <path
        d="M905.36 279.02V276.09C904.84 276.41 903.79 277.07 903.73 277.14C903.66 277.21 903.41 278.94 903.29 279.79L905.37 279.02H905.36Z"
        fill="#69B56F"
      />
      <path
        d="M905.36 279.02V276.09C904.84 276.41 903.79 277.07 903.73 277.14C903.66 277.21 903.41 278.94 903.29 279.79L905.37 279.02H905.36Z"
        fill="black"
      />
      <path
        d="M872.97 282.74V285.67C873.49 285.35 874.54 284.69 874.6 284.62C874.67 284.54 874.92 282.82 875.04 281.97L872.96 282.74H872.97Z"
        fill="#69B56F"
      />
      <path
        d="M872.97 282.74V285.67C873.49 285.35 874.54 284.69 874.6 284.62C874.67 284.54 874.92 282.82 875.04 281.97L872.96 282.74H872.97Z"
        fill="black"
      />
      <path
        d="M877.51 276.53L873.12 282.27C872.75 282.75 873.1 283.45 873.7 283.45H900.21C900.46 283.45 900.68 283.33 900.82 283.12L905.23 276.51C905.56 276.02 905.21 275.37 904.62 275.37H879.84C878.93 275.37 878.06 275.8 877.51 276.52V276.53Z"
        fill="#69B56F"
      />
      <path
        d="M939.55 152.39H918.62V182.86H939.55L926.7 270.23L909.08 294.46V313.18H918.99V380L971.12 339C974.36 339 976.99 336.37 976.99 333.13V171.41C976.99 169.01 975.53 166.85 973.31 165.96L939.55 152.39Z"
        fill="#2F3E95"
      />
      <path
        d="M816.2 294.46H909.08V313.18H822.07C818.83 313.18 816.2 310.55 816.2 307.31V294.46Z"
        fill="#394CBB"
      />
      <path
        d="M816.2 294.46H909.08V313.18H822.07C818.83 313.18 816.2 310.55 816.2 307.31V294.46Z"
        fill="black"
      />
      <path
        d="M827.22 182.86H847.78L846.97 189.04L846.95 189.16L841.98 227.31L837.36 262.8L836.39 270.23H926.7L938.58 189.48L939.55 182.86H925.6V152.39H833.08C829.84 152.39 827.21 155.02 827.21 158.26V182.86H827.22Z"
        fill="#4156CB"
      />
      <path
        d="M924.3 192.77H857.41C855.25 192.77 853.4 194.34 853.06 196.48L843.45 256.69C843.02 259.37 845.09 261.79 847.8 261.79H916.93C919.16 261.79 921.03 260.13 921.3 257.92L928.67 197.71C928.99 195.08 926.94 192.77 924.3 192.77Z"
        fill="#7FD3A5"
      />
      <path
        d="M837.36 262.8C838.1 264.16 839.54 265.09 841.22 265.09H863.92C860.81 265.09 858.46 263.9 856.73 261.79C847.96 251.07 855.37 216.65 862.18 194.53C862.36 193.94 862.42 193.35 862.36 192.78C862.22 191.27 861.31 189.91 859.97 189.22C859.45 188.95 858.86 188.77 858.22 188.73L855.54 188.55C854.61 188.49 853.71 188.72 852.95 189.19C852.37 189.54 851.88 190.03 851.51 190.63C849.9 193.28 847.98 196.52 847.76 197.18C847.54 197.83 844.5 214.04 841.97 227.32L837.35 262.81L837.36 262.8Z"
        fill="#7FD3A5"
      />
      <path
        d="M837.36 262.8C838.1 264.16 839.54 265.09 841.22 265.09H863.92C860.81 265.09 858.46 263.9 856.73 261.79C847.96 251.07 855.37 216.65 862.18 194.53C862.36 193.94 862.42 193.35 862.36 192.78C862.22 191.27 861.31 189.91 859.97 189.22C859.45 188.95 858.86 188.77 858.22 188.73L855.54 188.55C854.61 188.49 853.71 188.72 852.95 189.19C852.37 189.54 851.88 190.03 851.51 190.63C849.9 193.28 847.98 196.52 847.76 197.18C847.54 197.83 844.5 214.04 841.97 227.32L837.35 262.81L837.36 262.8Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M903.21 208.191C903.21 204.131 906.5 200.851 910.55 200.851C914.6 200.851 917.89 204.141 917.89 208.191C917.89 212.241 914.6 215.531 910.55 215.531C910.55 211.471 907.26 208.191 903.21 208.191Z"
        fill="white"
      />
      <path
        d="M901.74 222.14C904.783 222.14 907.25 219.673 907.25 216.63C907.25 213.587 904.783 211.12 901.74 211.12C898.697 211.12 896.23 213.587 896.23 216.63C896.23 219.673 898.697 222.14 901.74 222.14Z"
        fill="white"
      />
      <path
        d="M939.55 182.86L847.77 183.23L846.95 189.17L938.58 189.49L939.55 182.86Z"
        fill="#4156CB"
      />
      <path
        d="M939.55 182.86L847.77 183.23L846.95 189.17L938.58 189.49L939.55 182.86Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M878.7 165.67V170.35H880.2V165.67H878.7ZM878.7 165.67V170.35H880.2V165.67H878.7ZM911.47 165.73V170.29H913.03V165.73H911.47ZM911.47 165.73V170.29H913.03V165.73H911.47ZM889.63 165.73V170.29H891.19V165.73H889.63ZM889.63 165.73V170.29H891.19V165.73H889.63ZM916.8 163.47V161.91H907.71V163.47H907.43V161.91H899.9V166.59H896.5V163.47H894.94V161.91H885.85V163.47H884.02V161.91H862.45V163.47H862.17V161.91H853.08V163.47H852.8V161.91H832.79V167.88H834.35V174.12H841.88V167.88H842.16V174.12H852.81V172.56H853.09V174.12H862.18V172.56H862.46V174.12H884.03V172.56H885.86V174.12H894.95V172.56H896.78V174.12H905.87V172.56H907.7V174.12H916.79V172.56H918.35V163.47H916.79H916.8ZM841.27 165.67H839.71V171.91H836.59V165.67H835.03V164.11H841.27V165.67ZM850.63 165.67H847.51V167.23H850.63V168.79H847.51V170.35H850.63V171.91H844.39V164.11H850.63V165.67ZM861.55 167.23H858.43V165.67H856.87V170.35H858.43V168.79H861.55V170.35H859.99V171.91H855.31V170.35H853.75V165.67H855.31V164.11H859.99V165.67H861.55V167.23ZM872.47 171.91H869.35V168.79H867.79V171.91H864.67V164.11H867.79V167.23H869.35V164.11H872.47V171.91ZM883.39 170.35H881.83V171.91H875.59V164.11H881.83V165.67H883.39V170.35ZM894.31 170.35H892.75V171.91H888.07V170.35H886.51V165.67H888.07V164.11H892.75V165.67H894.31V170.35ZM905.23 170.35H903.67V171.91H898.99V170.35H897.43V168.79H900.55V170.35H902.11V164.11H905.23V170.35ZM916.15 170.35H914.59V171.91H909.91V170.35H908.35V165.67H909.91V164.11H914.59V165.67H916.15V170.35ZM911.47 170.29H913.03V165.73H911.47V170.29ZM889.63 170.29H891.19V165.73H889.63V170.29ZM878.71 170.35H880.21V165.67H878.71V170.35Z"
        fill="#7FD3A5"
      />
      <path
        opacity="0.32"
        d="M612.56 41.67L273.14 46.14L794.02 630.5L742.93 664.34L946.78 694.88L700.78 704.35L521.02 824L726.51 682.11L217.79 46.87L106.64 48.33L72 156.33L521.02 824L971 827.88L972 816.18V1059H23V27H972V512.6L612.56 41.67Z"
        fill="#181C26"
      />
      <path
        opacity="0.32"
        d="M972 715.41V757.12L735.5 730.68L972 715.41Z"
        fill="#181C26"
      />
      <path
        opacity="0.32"
        d="M726.51 682.11L521.02 824L72 156.33L106.64 48.3301L217.79 46.8701L726.51 682.11Z"
        fill="url(#paint0_linear_418_31270)"
      />
      <path
        opacity="0.32"
        d="M972 512.6L794.02 630.5L273.14 46.1399L612.56 41.6699L972 512.6Z"
        fill="url(#paint1_linear_418_31270)"
      />
    </g>
    <path
      d="M152.5 973.5C152.5 973.5 128.5 969.5 123.5 991.5"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M796.5 445.5C811.07 440.34 816.51 435.18 817.03 430.5"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M332.3 757.68H288.29"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M222.75 678.5H209.93V767.34C209.93 791.88 229.82 811.76 254.35 811.76V737.7C254.35 732 255.96 726.67 258.76 722.15C263.97 713.72 273.3 708.11 283.93 708.11H332.82V678.5H222.75Z"
      fill="#FF661A"
    />
    <path
      d="M358 678.5C358 689.28 355.11 699.39 350.08 708.11H332.83V678.5H358Z"
      fill="white"
    />
    <path d="M254.35 663.69H209.93V678.5H254.35V663.69Z" fill="#BC5FDD" />
    <path d="M239.54 663.69H209.93V634.08L239.54 663.69Z" fill="#FF661A" />
    <path d="M269.16 663.69H298.77V634.08L269.16 663.69Z" fill="#FF661A" />
    <path
      d="M209.93 656.29V671.1C201.76 671.1 195.12 664.47 195.12 656.29H209.93Z"
      fill="#BC5FDD"
    />
    <path
      d="M209.93 671.09V685.9H195.12C195.12 677.73 201.75 671.09 209.93 671.09Z"
      fill="#BC5FDD"
    />
    <path
      d="M350.08 708.11C339.83 725.82 320.69 737.72 298.77 737.72V767.33C298.77 791.87 278.88 811.75 254.35 811.75V737.69C254.35 731.99 255.96 726.66 258.76 722.14C263.97 713.71 273.3 708.1 283.93 708.1H350.07L350.08 708.11Z"
      fill="#FFE9CF"
    />
    <path
      d="M358 678.5C358 689.28 355.11 699.39 350.08 708.11H332.83V678.5H358Z"
      fill="#3F2214"
    />
    <path d="M298.77 663.69H254.35V678.5H298.77V663.69Z" fill="#A14ABF" />
    <path
      d="M243.99 696.46C243.99 696.46 253.84 689.37 264.72 696.46"
      stroke="#3F2214"
      stroke-width="5.92"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M216.5 757.5C216.5 757.5 187.5 783.5 226.5 785.5"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M279.5 835.5C298.5 825.5 279.5 812.5 279.5 812.5"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M213.63 808.061L202.5 828.501"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M520.3 801.68H476.29"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M410.75 722.5H397.93V811.34C397.93 835.88 417.82 855.76 442.35 855.76V781.7C442.35 776 443.96 770.67 446.76 766.15C451.97 757.72 461.3 752.11 471.93 752.11H520.82V722.5H410.75Z"
      fill="#FF661A"
    />
    <path
      d="M546 722.5C546 733.28 543.11 743.39 538.08 752.11H520.83V722.5H546Z"
      fill="white"
    />
    <path d="M442.35 707.69H397.93V722.5H442.35V707.69Z" fill="#FFCD1A" />
    <path d="M427.54 707.69H397.93V678.08L427.54 707.69Z" fill="#FF661A" />
    <path d="M457.16 707.69H486.77V678.08L457.16 707.69Z" fill="#FF661A" />
    <path
      d="M397.93 700.29V715.1C389.76 715.1 383.12 708.47 383.12 700.29H397.93Z"
      fill="#FFCD1A"
    />
    <path
      d="M397.93 715.09V729.9H383.12C383.12 721.73 389.75 715.09 397.93 715.09Z"
      fill="#FFCD1A"
    />
    <path
      d="M538.08 752.11C527.83 769.82 508.69 781.72 486.77 781.72V811.33C486.77 835.87 466.88 855.75 442.35 855.75V781.69C442.35 775.99 443.96 770.66 446.76 766.14C451.97 757.71 461.3 752.1 471.93 752.1H538.07L538.08 752.11Z"
      fill="#FFE9CF"
    />
    <path
      d="M546 722.5C546 733.28 543.11 743.39 538.08 752.11H520.83V722.5H546Z"
      fill="#3F2214"
    />
    <path d="M486.77 707.69H442.35V722.5H486.77V707.69Z" fill="#F2A200" />
    <path
      d="M431.99 740.46C431.99 740.46 441.84 733.37 452.72 740.46"
      stroke="#3F2214"
      stroke-width="5.92"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M404.5 801.5C404.5 801.5 375.5 827.5 414.5 829.5"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M467.5 879.5C486.5 869.5 467.5 856.5 467.5 856.5"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M401.63 852.061L390.5 872.501"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M712.3 846.68H668.29"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M602.75 767.5H589.93V856.34C589.93 880.88 609.82 900.76 634.35 900.76V826.7C634.35 821 635.96 815.67 638.76 811.15C643.97 802.72 653.3 797.11 663.93 797.11H712.82V767.5H602.75Z"
      fill="#FF661A"
    />
    <path
      d="M738 767.5C738 778.28 735.11 788.39 730.08 797.11H712.83V767.5H738Z"
      fill="white"
    />
    <path d="M634.35 752.69H589.93V767.5H634.35V752.69Z" fill="#18B76B" />
    <path d="M619.54 752.69H589.93V723.08L619.54 752.69Z" fill="#FF661A" />
    <path d="M649.16 752.69H678.77V723.08L649.16 752.69Z" fill="#FF661A" />
    <path
      d="M589.93 745.29V760.1C581.76 760.1 575.12 753.47 575.12 745.29H589.93Z"
      fill="#18B76B"
    />
    <path
      d="M589.93 760.09V774.9H575.12C575.12 766.73 581.75 760.09 589.93 760.09Z"
      fill="#18B76B"
    />
    <path
      d="M730.08 797.11C719.83 814.82 700.69 826.72 678.77 826.72V856.33C678.77 880.87 658.88 900.75 634.35 900.75V826.69C634.35 820.99 635.96 815.66 638.76 811.14C643.97 802.71 653.3 797.1 663.93 797.1H730.07L730.08 797.11Z"
      fill="#FFE9CF"
    />
    <path
      d="M738 767.5C738 778.28 735.11 788.39 730.08 797.11H712.83V767.5H738Z"
      fill="#3F2214"
    />
    <path d="M678.77 752.69H634.35V767.5H678.77V752.69Z" fill="#139456" />
    <path
      d="M623.99 785.46C623.99 785.46 633.84 778.37 644.72 785.46"
      stroke="#3F2214"
      stroke-width="5.92"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M596.5 846.5C596.5 846.5 567.5 872.5 606.5 874.5"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M659.5 924.5C678.5 914.5 659.5 901.5 659.5 901.5"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M593.63 897.061L582.5 917.501"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M902.3 890.68H858.29"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M792.75 811.5H779.93V900.34C779.93 924.88 799.82 944.76 824.35 944.76V870.7C824.35 865 825.96 859.67 828.76 855.15C833.97 846.72 843.3 841.11 853.93 841.11H902.82V811.5H792.75Z"
      fill="#FF661A"
    />
    <path
      d="M928 811.5C928 822.28 925.11 832.39 920.08 841.11H902.83V811.5H928Z"
      fill="white"
    />
    <path d="M824.35 796.69H779.93V811.5H824.35V796.69Z" fill="#BC5FDD" />
    <path d="M809.54 796.69H779.93V767.08L809.54 796.69Z" fill="#FF661A" />
    <path d="M839.16 796.69H868.77V767.08L839.16 796.69Z" fill="#FF661A" />
    <path
      d="M779.93 789.29V804.1C771.76 804.1 765.12 797.47 765.12 789.29H779.93Z"
      fill="#BC5FDD"
    />
    <path
      d="M779.93 804.09V818.9H765.12C765.12 810.73 771.75 804.09 779.93 804.09Z"
      fill="#BC5FDD"
    />
    <path
      d="M920.08 841.11C909.83 858.82 890.69 870.72 868.77 870.72V900.33C868.77 924.87 848.88 944.75 824.35 944.75V870.69C824.35 864.99 825.96 859.66 828.76 855.14C833.97 846.71 843.3 841.1 853.93 841.1H920.07L920.08 841.11Z"
      fill="#FFE9CF"
    />
    <path
      d="M928 811.5C928 822.28 925.11 832.39 920.08 841.11H902.83V811.5H928Z"
      fill="#3F2214"
    />
    <path d="M868.77 796.69H824.35V811.5H868.77V796.69Z" fill="#A14ABF" />
    <path
      d="M813.99 829.46C813.99 829.46 823.84 822.37 834.72 829.46"
      stroke="#3F2214"
      stroke-width="5.92"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M786.5 890.5C786.5 890.5 757.5 916.5 796.5 918.5"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M849.5 968.5C868.5 958.5 849.5 945.5 849.5 945.5"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M783.63 941.061L772.5 961.501"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M865.3 654.68H821.29"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M755.75 575.5H742.93V664.34C742.93 688.88 762.82 708.76 787.35 708.76V634.7C787.35 629 788.96 623.67 791.76 619.15C796.97 610.72 806.3 605.11 816.93 605.11H865.82V575.5H755.75Z"
      fill="#FF661A"
    />
    <path
      d="M891 575.5C891 586.28 888.11 596.39 883.08 605.11H865.83V575.5H891Z"
      fill="white"
    />
    <path d="M787.35 560.69H742.93V575.5H787.35V560.69Z" fill="#18B76B" />
    <path d="M772.54 560.69H742.93V531.08L772.54 560.69Z" fill="#FF661A" />
    <path d="M802.16 560.69H831.77V531.08L802.16 560.69Z" fill="#FF661A" />
    <path
      d="M742.93 553.29V568.1C734.76 568.1 728.12 561.47 728.12 553.29H742.93Z"
      fill="#18B76B"
    />
    <path
      d="M742.93 568.09V582.9H728.12C728.12 574.73 734.75 568.09 742.93 568.09Z"
      fill="#18B76B"
    />
    <path
      d="M883.08 605.11C872.83 622.82 853.69 634.72 831.77 634.72V664.33C831.77 688.87 811.88 708.75 787.35 708.75V634.69C787.35 628.99 788.96 623.66 791.76 619.14C796.97 610.71 806.3 605.1 816.93 605.1H883.07L883.08 605.11Z"
      fill="#FFE9CF"
    />
    <path
      d="M891 575.5C891 586.28 888.11 596.39 883.08 605.11H865.83V575.5H891Z"
      fill="#3F2214"
    />
    <path d="M831.77 560.69H787.35V575.5H831.77V560.69Z" fill="#139456" />
    <path
      d="M776.99 593.46C776.99 593.46 786.84 586.37 797.72 593.46"
      stroke="#3F2214"
      stroke-width="5.92"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M749.5 654.5C749.5 654.5 720.5 680.5 759.5 682.5"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M812.5 732.5C831.5 722.5 812.5 709.5 812.5 709.5"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M746.63 705.061L735.5 725.501"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M668.3 610.68H624.29"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M558.75 531.5H545.93V620.34C545.93 644.88 565.82 664.76 590.35 664.76V590.7C590.35 585 591.96 579.67 594.76 575.15C599.97 566.72 609.3 561.11 619.93 561.11H668.82V531.5H558.75Z"
      fill="#FF661A"
    />
    <path
      d="M694 531.5C694 542.28 691.11 552.39 686.08 561.11H668.83V531.5H694Z"
      fill="white"
    />
    <path d="M590.35 516.69H545.93V531.5H590.35V516.69Z" fill="#BC5FDD" />
    <path d="M575.54 516.69H545.93V487.08L575.54 516.69Z" fill="#FF661A" />
    <path d="M605.16 516.69H634.77V487.08L605.16 516.69Z" fill="#FF661A" />
    <path
      d="M545.93 509.29V524.1C537.76 524.1 531.12 517.47 531.12 509.29H545.93Z"
      fill="#BC5FDD"
    />
    <path
      d="M545.93 524.09V538.9H531.12C531.12 530.73 537.75 524.09 545.93 524.09Z"
      fill="#BC5FDD"
    />
    <path
      d="M686.08 561.11C675.83 578.82 656.69 590.72 634.77 590.72V620.33C634.77 644.87 614.88 664.75 590.35 664.75V590.69C590.35 584.99 591.96 579.66 594.76 575.14C599.97 566.71 609.3 561.1 619.93 561.1H686.07L686.08 561.11Z"
      fill="#FFE9CF"
    />
    <path
      d="M694 531.5C694 542.28 691.11 552.39 686.08 561.11H668.83V531.5H694Z"
      fill="#3F2214"
    />
    <path d="M634.77 516.69H590.35V531.5H634.77V516.69Z" fill="#A14ABF" />
    <path
      d="M579.99 549.46C579.99 549.46 589.84 542.37 600.72 549.46"
      stroke="#3F2214"
      stroke-width="5.92"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M552.5 610.5C552.5 610.5 523.5 636.5 562.5 638.5"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M615.5 688.5C634.5 678.5 615.5 665.5 615.5 665.5"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M549.63 661.061L538.5 681.501"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M470.3 565.68H426.29"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M360.75 486.5H347.93V575.34C347.93 599.88 367.82 619.76 392.35 619.76V545.7C392.35 540 393.96 534.67 396.76 530.15C401.97 521.72 411.3 516.11 421.93 516.11H470.82V486.5H360.75Z"
      fill="#FF661A"
    />
    <path
      d="M496 486.5C496 497.28 493.11 507.39 488.08 516.11H470.83V486.5H496Z"
      fill="white"
    />
    <path d="M392.35 471.69H347.93V486.5H392.35V471.69Z" fill="#FFCD1A" />
    <path d="M377.54 471.69H347.93V442.08L377.54 471.69Z" fill="#FF661A" />
    <path d="M407.16 471.69H436.77V442.08L407.16 471.69Z" fill="#FF661A" />
    <path
      d="M347.93 464.29V479.1C339.76 479.1 333.12 472.47 333.12 464.29H347.93Z"
      fill="#FFCD1A"
    />
    <path
      d="M347.93 479.09V493.9H333.12C333.12 485.73 339.75 479.09 347.93 479.09Z"
      fill="#FFCD1A"
    />
    <path
      d="M488.08 516.11C477.83 533.82 458.69 545.72 436.77 545.72V575.33C436.77 599.87 416.88 619.75 392.35 619.75V545.69C392.35 539.99 393.96 534.66 396.76 530.14C401.97 521.71 411.3 516.1 421.93 516.1H488.07L488.08 516.11Z"
      fill="#FFE9CF"
    />
    <path
      d="M496 486.5C496 497.28 493.11 507.39 488.08 516.11H470.83V486.5H496Z"
      fill="#3F2214"
    />
    <path d="M436.77 471.69H392.35V486.5H436.77V471.69Z" fill="#F2A200" />
    <path
      d="M381.99 504.46C381.99 504.46 391.84 497.37 402.72 504.46"
      stroke="#3F2214"
      stroke-width="5.92"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M354.5 565.5C354.5 565.5 325.5 591.5 364.5 593.5"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M417.5 643.5C436.5 633.5 417.5 620.5 417.5 620.5"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M351.63 616.061L340.5 636.501"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M694.5 424.5C694.5 424.5 710.5 436.5 728.5 432.5"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M793.37 339.5H806.19V428.34C806.19 452.88 786.3 472.76 761.77 472.76V398.7C761.77 393 760.16 387.67 757.36 383.15C752.15 374.72 742.82 369.11 732.19 369.11H683.3V339.5H793.37Z"
      fill="#FF661A"
    />
    <path
      d="M658.12 339.5C658.12 350.28 661.01 360.39 666.04 369.11H683.29V339.5H658.12Z"
      fill="white"
    />
    <path d="M806.19 324.69H761.77V339.5H806.19V324.69Z" fill="#282F3E" />
    <path d="M776.58 324.69H806.19V295.08L776.58 324.69Z" fill="#FF661A" />
    <path d="M746.97 324.69H717.35V295.08L746.97 324.69Z" fill="#FF661A" />
    <path
      d="M806.19 317.29V332.1C814.36 332.1 821 325.47 821 317.29H806.19Z"
      fill="#282F3E"
    />
    <path
      d="M806.19 332.09V346.9H821C821 338.73 814.37 332.09 806.19 332.09Z"
      fill="#282F3E"
    />
    <path
      d="M666.05 369.11C676.3 386.82 695.44 398.72 717.36 398.72V428.33C717.36 452.87 737.25 472.75 761.78 472.75V398.69C761.78 392.99 760.17 387.66 757.37 383.14C752.16 374.71 742.83 369.1 732.2 369.1H666.06L666.05 369.11Z"
      fill="#FFE9CF"
    />
    <path
      d="M658.12 339.5C658.12 350.28 661.01 360.39 666.04 369.11H683.29V339.5H658.12Z"
      fill="#3F2214"
    />
    <path d="M761.77 324.69H717.35V339.5H761.77V324.69Z" fill="#181C26" />
    <path
      d="M772.14 357.46C772.14 357.46 762.29 350.37 751.41 357.46"
      stroke="#3F2214"
      stroke-width="5.92"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M761 368L751 364"
      stroke="#AA4B22"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M734.5 483.5H747.5"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M768.5 489.5H783.5"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M291 896.5C291 907.28 288.11 917.39 283.08 926.11H265.83V896.5H291Z"
      fill="white"
    />
    <path d="M187.35 881.69H142.93V896.5H187.35V881.69Z" fill="#EAECF0" />
    <path d="M172.54 881.69H142.93V852.08L172.54 881.69Z" fill="#FF661A" />
    <path d="M202.16 881.69H231.77V852.08L202.16 881.69Z" fill="#FF661A" />
    <path
      d="M142.93 874.29V889.1C134.76 889.1 128.12 882.47 128.12 874.29H142.93Z"
      fill="#EAECF0"
    />
    <path
      d="M142.93 889.09V903.9H128.12C128.12 895.73 134.75 889.09 142.93 889.09Z"
      fill="#EAECF0"
    />
    <path
      d="M265.83 896.5V926.11H236.13C219.83 926.11 206.61 939.33 206.61 955.63V1025.37C200.79 1028.18 194.26 1029.75 187.36 1029.75C162.82 1029.75 142.94 1009.86 142.94 985.33V896.49H265.84L265.83 896.5Z"
      fill="#FF661A"
    />
    <path
      d="M291 896.5C291 907.28 288.11 917.39 283.08 926.11H265.83V896.5H291Z"
      fill="#3F2214"
    />
    <path d="M231.77 881.69H187.35V896.5H231.77V881.69Z" fill="#D3D6DE" />
    <path
      d="M204.73 918.05C204.73 918.05 212.44 908.67 224.77 912.75"
      stroke="#3F2214"
      stroke-width="5.92"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M175.01 1038.5H165.5"
      stroke="#723621"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M283.08 926.11C272.83 943.82 253.69 955.72 231.77 955.72V985.33C231.77 1002.96 221.49 1018.2 206.6 1025.37V955.63C206.6 939.33 219.82 926.11 236.12 926.11H283.07H283.08Z"
      fill="#FFE9CF"
    />
    <path
      d="M817.03 430.5C818.23 419.75 793.5 411.5 793.5 411.5"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M247.5 996.5C242.5 973.5 217.5 973.5 217.5 973.5"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M226.5 1038.5L206.62 1037.06"
      stroke="#AA4B22"
      stroke-width="14.81"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M751 377.11V446.11C742.16 446.11 735 438.95 735 430.11V385.11H674V430.11C674 434.53 672.21 438.53 669.32 441.43C666.42 444.32 662.42 446.11 658 446.11V377.11C658 372.69 661.58 369.11 666 369.11H743C747.42 369.11 751 372.69 751 377.11Z"
      fill="#ABB4C7"
    />
    <path
      d="M696 391V442.25C698.64 442.25 701.03 441.18 702.76 439.46C704.49 437.73 705.55 435.34 705.55 432.7V391.01H695.99L696 391Z"
      fill="#ABB4C7"
    />
    <defs>
      <linearGradient
        id="paint0_linear_418_31270"
        x1="335.5"
        y1="330.31"
        x2="585.91"
        y2="697.53"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.42" stop-color="#8A8A8A" />
        <stop offset="0.93" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_418_31270"
        x1="516.87"
        y1="132.53"
        x2="964.85"
        y2="668.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.42" stop-color="#8A8A8A" />
        <stop offset="0.93" />
      </linearGradient>
    </defs>
  </svg>
</template>
