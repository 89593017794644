import { defineCustomElement } from "vue";

import AppProvider from "@/providers/AppProvider.ce.vue";
import ThemeProvider from "@/providers/ThemeProvider.ce.vue";

// Icons
import FoxKurama from "@/components/icons/FoxKurama.ce.vue";
import IconCage from "@/components/icons/IconCage.ce.vue";
import IconEmail from "@/components/icons/IconEmail.ce.vue";
import Eye from "@/components/icons/Eye.vue";
import EyeSlash from "@/components/icons/EyeSlash.vue";

// Ilustrations
import TechDojoLogo from "@/components/icons/TechDojoLogo.ce.vue";
import IllustrationLogin from "@/components/illustrations/Login.ce.vue";
import IllustrationLoginMobile from "@/components/illustrations/LoginMobile.ce.vue";
import IllustrationRegister from "@/components/illustrations/Register.ce.vue";
import RegisterMobile from "@/components/illustrations/RegisterMobile.ce.vue";

// Registrando Webcomponents
customElements.define("icon-fox-kurama", defineCustomElement(FoxKurama));

customElements.define("icon-cage", defineCustomElement(IconCage));
customElements.define("icon-email", defineCustomElement(IconEmail));
customElements.define("icon-eye", defineCustomElement(Eye));
customElements.define("icon-eye-slash", defineCustomElement(EyeSlash));

customElements.define(
  "icon-techdojo-logomark",
  defineCustomElement(TechDojoLogo)
);

customElements.define(
  "illustration-login",
  defineCustomElement(IllustrationLogin)
);

customElements.define(
  "illustration-login-mobile",
  defineCustomElement(IllustrationLoginMobile)
);

customElements.define(
  "illustration-register",
  defineCustomElement(IllustrationRegister)
);

customElements.define(
  "illustration-register-mobile",
  defineCustomElement(RegisterMobile)
);

customElements.define("tj-app-provider", defineCustomElement(AppProvider));
customElements.define("tj-theme-provider", defineCustomElement(ThemeProvider));
