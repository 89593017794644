<script setup lang="ts">
import { onMounted } from "vue";

onMounted(() => {
  document.body.classList.remove("hidden");
});
</script>

<template>
  <slot />
</template>
